import { EntityWizardInterface } from '../EntityWizardInterface';
import { PATH_DASHBOARD } from '../../routes/paths';
import { HotelThirdpartyMarkup, HotelThirdpartyMarkupForm } from '../masters/HotelThirdpartyMarkup';
import { ClientGroup, ClientGroupForm } from '../masters/ClientGroup';
import { Client, ClientForm } from '../masters/Client';

export const HotelThirdpartyMarkupWiz: EntityWizardInterface = {
  title: 'Hotel Thridparty Markup',
  entityName: 'HotelThirdpartyMarkup',
  defaultTab: 'HotelThirdpartyMarkup',
  tabs: [
    {
      tabTitle: 'HotelThirdpartyMarkup',
      field: 'HotelThirdpartyMarkup',
      ModuleName: 'HTM',
      icon: '',
      component: 'EDIT',
      Entity: HotelThirdpartyMarkup,
      EntityForm: HotelThirdpartyMarkupForm,
      parentEntityName: '',
      entityName: '',
      entityWrapperName: ''
    },
    {
      tabTitle: 'Client Group',
      field: 'ClientGroup',
      ModuleName: 'HTM',
      icon: '',
      component: 'SELECT',
      Entity: ClientGroup,
      EntityForm: ClientGroupForm,
      parentEntityName: 'HotelThirdpartyMarkup',
      entityName: 'ClientGroup',
      entityWrapperName: 'BaseEntity'
    },
    {
      tabTitle: 'Clients',
      field: 'Clients',
      ModuleName: 'HTM',
      icon: '',
      component: 'SELECT',
      Entity: Client,
      EntityForm: ClientForm,
      parentEntityName: 'HotelThirdpartyMarkup',
      entityName: 'Clients',
      entityWrapperName: 'BaseEntity'
    }
  ],
  headerBreadCrumbs: [
    { name: 'Dashboard', href: PATH_DASHBOARD.root },
    { name: 'Wizards', href: PATH_DASHBOARD.wizards.root },
    { name: 'HotelThirdpartyMarkup', href: PATH_DASHBOARD.wizards.hotelthirdpartymarkup },
    { name: 'General Properties' }
  ]
};
