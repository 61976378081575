import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function TablePagination(theme: Theme) {
  return {
    MuiInputBase: {
      defaultProps: {
        fullWidth: false
      }
    },
    MuiSelect: {
      defaultProps: {
        fullWidth: false
      }
    }
  };
}
