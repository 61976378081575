import { PATH_DASHBOARD } from './routes/paths';

export const endPoints = {
  prodRptEndPoint: 'https://reportservice.scalehorizon.com/report-api/1.0/',
  prodBOEndPoint: 'https://dbservice.scalehorizon.com/data-api/1.0/',
  prodHotelApiEndPoint: 'https://uiservice.scalehorizon.com/hotel-uapi/1.0/',
  prodFlightApiEndPoint: 'https://uiservice.scalehorizon.com/flight-uapi/1.0/',
  prodActivityApiEndPoint: 'https://uiservice.scalehorizon.com/activity-uapi/1.0/',
  prodCDNEndPoint: 'https://cdn.scalehorizon.com',
  prodBosonEndPoint: 'https://boson.scalehorizon.com/',

  devRptEndPoint: 'http://localhost:5000/report-api/1.0/',
  devBOEndPoint: 'http://localhost:8089/data-api/1.0/',
  devHotelApiEndPoint: 'http://localhost:8082/hotel-uapi/1.0/',
  devFlightApiEndPoint: 'http://localhost:8082/flight-uapi/1.0/',
  devActivityApiEndPoint: 'http://localhost:8082/activity-uapi/1.0/',
  devCDNEndPoint: 'http://localhost:8086',
  devBosonEndPoint: 'http://185.70.196.250:8002/',

  hbImgEndPoint: 'https://photos.hotelbeds.com/giata',
  exImgEndPoint: 'https://images.trvl-media.com'
};
/*
export const endPoints = {
  prodRptEndPoint: 'https://reportservicecloud.scalehorizon.com/report-api/1.0/',
  prodBOEndPoint: 'https://dbservicecloud.scalehorizon.com/data-api/1.0/',
  prodHotelApiEndPoint: 'https://uiservicecloud.scalehorizon.com/hotel-uapi/1.0/',
  prodFlightApiEndPoint: 'https://uiservicecloud.scalehorizon.com/flight-uapi/1.0/',
  prodActivityApiEndPoint: 'https://uiservicecloud.scalehorizon.com/activity-uapi/1.0/',
  prodCDNEndPoint: 'https://cdncloud.scalehorizon.com',
  prodBosonEndPoint: 'https://bosoncloud.scalehorizon.com/',

  devRptEndPoint: 'http://localhost:5000/report-api/1.0/',
  devBOEndPoint: 'http://localhost:8089/data-api/1.0/',
  devHotelApiEndPoint: 'http://localhost:8082/hotel-uapi/1.0/',
  devFlightApiEndPoint: 'http://localhost:8082/flight-uapi/1.0/',
  devActivityApiEndPoint: 'http://localhost:8082/activity-uapi/1.0/',
  devCDNEndPoint: 'http://localhost:8086',
  devBosonEndPoint: 'http://185.70.196.250:8002/',

  hbImgEndPoint: 'https://photos.hotelbeds.com/giata',
  exImgEndPoint: 'https://images.trvl-media.com'
};*/

export const engineType: string = 'B2B';

export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;

export const PATH_AFTER_LOGIN = PATH_DASHBOARD.root;

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20
};

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32
};

export const B2BHEADER = {
  MOBILE_HEIGHT: 95,
  MAIN_DESKTOP_HEIGHT: 120,
  DASHBOARD_DESKTOP_HEIGHT: 130,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 130 - 0
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32
};
