import * as Yup from 'yup';
import { EntityInterface } from '../EntityInterface';
import { EntityFormInterface } from '../EntityFormInterface';
import { PATH_DASHBOARD } from '../../routes/paths';
import { yuphelper } from '../../utils/yuphelper';

const EntityYupSchema = {
  Code: yuphelper.CODE3_REQ,
  Name: yuphelper.STR_REQ,
  FromDate: yuphelper.STR_REQ,
  ToDate: yuphelper.STR_REQ
};

const getFormikInitialValuesHandler = (entity: any) =>
  entity
    ? {
        Code: entity.Code,
        Name: entity.Name,
        ContractType: entity.ContractType,
        FareType: entity.FareType,
        TaxOnMarkup: entity.TaxOnMarkup,
        FromDate: entity.FromDate,
        ToDate: entity.ToDate,
        AdultRate: entity.AdultRate,
        ChildRate: entity.ChildRate,
        InfantInLapRate: entity.InfantInLapRate,
        InfantOnSeatRate: entity.InfantOnSeatRate,
        IsPercentage: entity.IsPercentage
      }
    : {
        Code: '',
        Name: '',
        ContractType: 'BO',
        FareType: 'ANY',
        TaxOnMarkup: false,
        FromDate: new Date(),
        ToDate: new Date(),
        AdultRate: '0',
        ChildRate: '0',
        InfantInLapRate: '0',
        InfantOnSeatRate: '0',
        IsPercentage: true
      };

export const FlightMarkupForm: EntityFormInterface = {
  title: 'Flight Markup',
  moduleName: 'FTM',
  entityName: 'FlightMarkup',
  entityType: 'SELF',
  entityYupSchema: EntityYupSchema,
  getFormikInitialValues: getFormikInitialValuesHandler,
  elementGroups: [
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Code',
          field: 'Code',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        },
        {
          label: 'Name',
          field: 'Name',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'ContractType',
          field: 'ContractType',
          type: 'string',
          control: 'select',
          values: [
            {
              label: 'Seat Only',
              value: 'SO'
            },
            {
              label: 'Inclusive Tours',
              value: 'IT'
            },
            {
              label: 'Both',
              value: 'BO'
            }
          ],
          width: 100
        },
        {
          label: 'FareType',
          field: 'FareType',
          type: 'string',
          control: 'select',
          values: [
            {
              label: 'International',
              value: 'INT'
            },
            {
              label: 'Domastic',
              value: 'DOM'
            },
            {
              label: 'Any',
              value: 'ANY'
            }
          ],
          width: 100
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'From Date',
          field: 'FromDate',
          type: 'date',
          control: 'datepicker',
          width: 100,
          values: []
        },
        {
          label: 'To Date',
          field: 'ToDate',
          type: 'date',
          control: 'datepicker',
          width: 100,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Adult',
          field: 'AdultRate',
          type: 'number',
          control: 'textbox',
          width: 100,
          values: []
        },
        {
          label: 'Child',
          field: 'ChildRate',
          type: 'number',
          control: 'textbox',
          width: 100,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Infant In Lap',
          field: 'InfantInLapRate',
          type: 'number',
          control: 'textbox',
          width: 100,
          values: []
        },
        {
          label: 'Infant On Seat',
          field: 'InfantOnSeatRate',
          type: 'number',
          control: 'textbox',
          width: 100,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'IsPercentage',
          field: 'IsPercentage',
          type: 'boolean',
          control: 'switch',
          values: [],
          width: 100
        },
        {
          label: 'Tax On Markup',
          field: 'TaxOnMarkup',
          type: 'boolean',
          control: 'switch',
          width: 100,
          values: []
        }
      ]
    }
  ]
};

export const FlightMarkup: EntityInterface = {
  title: 'Flight Markup',
  moduleName: 'FTM',
  entityName: 'FlightMarkup',
  entityType: 'SELF',
  entityForm: FlightMarkupForm,
  sortField: 'Name',
  sortOption: 'asc',
  filterField: 'Name',
  rowsPerPage: 5,
  filterOption: 'contains',
  wizardUrl: 'flightmarkupdetails',
  isDense: false,
  totalRecords: 10,
  tables: [
    {
      field: '_id',
      label: '_id',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: false
    },
    {
      field: 'Code',
      label: 'Code',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'Name',
      label: 'Name',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'ContractType',
      label: 'ContractType',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'FareType',
      label: 'FareType',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'TaxOnMarkup',
      label: 'TaxOnMarkup',
      alignRight: false,
      type: 'boolean',
      width: 120,
      filter: true
    },
    {
      field: 'FromDate',
      label: 'FromDate',
      alignRight: false,
      type: 'date',
      format: 'DD MMM YYYY HH:SS',
      width: 120,
      filter: true
    },
    {
      field: 'ToDate',
      label: 'ToDate',
      alignRight: false,
      type: 'date',
      format: 'DD MMM YYYY HH:SS',
      width: 120,
      filter: true
    },
    {
      field: 'AdultRate',
      label: 'AdultRate',
      alignRight: false,
      type: 'float',
      width: 120,
      filter: false
    },
    {
      field: 'ChildRate',
      label: 'ChildRate',
      alignRight: false,
      type: 'float',
      width: 120,
      filter: false
    },
    {
      field: 'InfantInLapRate',
      label: 'InfantInLapRate',
      alignRight: false,
      type: 'float',
      width: 120,
      filter: false
    },
    {
      field: 'InfantOnSeatRate',
      label: 'InfantOnSeatRate',
      alignRight: false,
      type: 'float',
      width: 120,
      filter: false
    },
    {
      field: 'IsPercentage',
      label: 'IsPercentage',
      alignRight: false,
      type: 'boolean',
      width: 120,
      filter: false
    },
    {
      field: 'CreatedBy',
      label: 'CreatedBy',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'CreatedDate',
      label: 'CreatedDate',
      alignRight: false,
      type: 'date',
      format: 'DD MMM YYYY HH:SS',
      width: 120,
      filter: false
    },
    {
      field: 'ModifiedBy',
      label: 'ModifiedBy',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'ModifiedDate',
      label: 'ModifiedDate',
      alignRight: false,
      type: 'date',
      format: 'DD MMM YYYY HH:SS',
      width: 120,
      filter: false
    }
  ],
  headerBreadCrumbs: [
    { name: 'Dashboard', href: PATH_DASHBOARD.root },
    { name: 'Wizards', href: PATH_DASHBOARD.wizards.root },
    { name: 'FlightMarkup', href: PATH_DASHBOARD.wizards.flightmarkup },
    { name: 'List' }
  ]
};

export default FlightMarkup;
