import * as Yup from 'yup';

const number_non_req = Yup.number().typeError('Enter a number');
const money_non_req = number_non_req.min(0);
const int_non_req = money_non_req.integer();
const email = Yup.string().email();

export const yuphelper = {
  STR_REQ: Yup.string().required('Required'),
  IN_CODE1_REQ: Yup.object()
    .shape({
      Code: Yup.string()
        .min(1, 'Required')
        .required('Required')
        .matches(/^[A-Za-z0-9]*$/, 'No special characters allowed')
    })
    .required('Required'),
  IN_CODE2_REQ: Yup.object()
    .shape({
      Code: Yup.string()
        .min(2, 'Required')
        .required('Required')
        .matches(/^[A-Za-z0-9]*$/, 'No special characters allowed')
    })
    .required('Required'),
  IN_CODE3_REQ: Yup.object()
    .shape({
      Code: Yup.string()
        .min(2, 'Required')
        .required('Required')
        .matches(/^[A-Za-z0-9]*$/, 'No special characters allowed')
    })
    .required('Required'),
  CODE3_REQ: Yup.string()
    .min(3, 'Min 3 characters required.')
    .required('Required')
    .matches(/^[A-Za-z0-9]*$/, 'No special characters allowed'),
  CODE2_REQ: Yup.string()
    .min(2, 'Min 2 characters required.')
    .required('Required')
    .matches(/^[A-Za-z0-9]*$/, 'No special characters allowed'),
  CODE1_REQ: Yup.string()
    .min(1, 'Min 1 characters required.')
    .required('Required')
    .matches(/^[A-Za-z0-9]*$/, 'No special characters allowed'),
  MONEY_NON_REQ: money_non_req,
  INT_NON_REQ: int_non_req,
  MONEY_REQ: money_non_req.required('Required'),
  INT_REQ: int_non_req.required('Required'),
  EMAIL: email,
  EMAIL_REQ: email.required('Email is required'),
  DATE_GT_REQ: Yup.date()
    .min(new Date(new Date().setHours(0, 0, 0, 0)), 'Date should be greater than today')
    .required('Required'),
  MSG_MINMAX: 'Min should be less than max',
  NUMBER: number_non_req.required('Required')
};
