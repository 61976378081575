import { forwardRef } from 'react';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------
interface MLoadingButtonProps extends LoadingButtonProps {
  Module: string;
  CrudType: string;
}

const MLoadingButton = forwardRef<HTMLButtonElement, MLoadingButtonProps>(
  ({ Module = '', CrudType = '', children, sx, ...other }, ref) => {
    const { userRights } = useAuth();

    const moduleRights =
      userRights && userRights.Rights.find((element: any) => element.ModuleCode === Module);

    // console.log(Module, CrudType, moduleRights);

    let isAuthorized: boolean = false;
    if (moduleRights) {
      if (moduleRights.Full) {
        isAuthorized = true;
      } else if (CrudType === 'Read' && moduleRights.Read) {
        isAuthorized = true;
      } else if ((CrudType === 'Create' || CrudType === 'ADD') && moduleRights.Create) {
        isAuthorized = true;
      } else if (CrudType === 'Delete' && moduleRights.Delete) {
        isAuthorized = true;
      } else if ((CrudType === 'Update' || CrudType === 'UPDATE') && moduleRights.Update) {
        isAuthorized = true;
      }
    }

    return (
      <LoadingButton disabled={!isAuthorized} ref={ref} sx={sx} {...other}>
        {children}
      </LoadingButton>
    );
  }
);

export default MLoadingButton;
