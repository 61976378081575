import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------
export default function InputLabel(theme: Theme) {
  return {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          /* marginLeft: 10,
          top: '50%',
          transform: 'translate(0,-50%'
          */
        }
      }
    }
  };
}
