import { EntityWizardInterface } from '../EntityWizardInterface';
import { PATH_DASHBOARD } from '../../routes/paths';
import { FlightMarkup, FlightMarkupForm } from '../masters/FlightMarkup';
import { Carrier, CarrierForm } from '../masters/Carrier';
import { CabinClass, CabinClassForm } from '../masters/CabinClass';
import { Client, ClientForm } from '../masters/Client';

export const FlightMarkupWiz: EntityWizardInterface = {
  title: 'Flight Markup',
  entityName: 'FlightMarkup',
  defaultTab: 'FlightMarkup',
  tabs: [
    {
      tabTitle: 'FlightMarkup',
      field: 'FlightMarkup',
      ModuleName: 'FTM',
      icon: '',
      component: 'EDIT',
      Entity: FlightMarkup,
      EntityForm: FlightMarkupForm,
      parentEntityName: '',
      entityName: '',
      entityWrapperName: ''
    },
    {
      tabTitle: 'Clients',
      field: 'Client',
      ModuleName: 'FTM',
      icon: '',
      component: 'SELECT',
      Entity: Client,
      EntityForm: ClientForm,
      parentEntityName: 'FlightMarkup',
      entityName: 'Clients',
      entityWrapperName: 'BaseEntity'
    },
    {
      tabTitle: 'Carriers',
      field: 'Carriers',
      ModuleName: 'FTM',
      icon: '',
      component: 'SELECT',
      Entity: Carrier,
      EntityForm: CarrierForm,
      parentEntityName: 'FlightMarkup',
      entityName: 'Carriers',
      entityWrapperName: 'BaseEntity'
    },
    {
      tabTitle: 'CabinClasses',
      field: 'CabinClass',
      ModuleName: 'FTM',
      icon: '',
      component: 'SELECT',
      Entity: CabinClass,
      EntityForm: CabinClassForm,
      parentEntityName: 'FlightMarkup',
      entityName: 'CabinClasses',
      entityWrapperName: 'BaseEntity'
    }
  ],
  headerBreadCrumbs: [
    { name: 'Dashboard', href: PATH_DASHBOARD.root },
    { name: 'Wizards', href: PATH_DASHBOARD.wizards.root },
    { name: 'FlightMarkup', href: PATH_DASHBOARD.wizards.flightmarkup },
    { name: 'General Properties' }
  ]
};
