import { Box, Grid, Stack, Typography, Card } from '@mui/material';
// ----------------------------------------------------------------------

export default function ServiceDeals() {
  return (
    <>
      <Box sx={{ mt: 8 }} id="destinations" />
      <Grid container spacing={10} sx={{ pr: 10, pl: 10 }}>
        <Grid item xs={12} md={4}>
          <Typography variant="h3">SERVICES</Typography>
        </Grid>
      </Grid>
      <Box sx={{ mt: 4 }} />
      <Grid container spacing={10} sx={{ pr: 10, pl: 10 }}>
        <Grid item xs={12} md={4}>
          <Card>
            <Box sx={{ width: '100%' }}>
              <Stack direction="column" alignItems="flex-start" justifyContent="flex-start">
                <Box
                  component="img"
                  src="https://cdn.scalehorizon.com/assets/images/home/SIGHTSEEING.jpeg"
                  sx={{ width: '100%', height: '50vh', objectFit: 'cover' }}
                />
                <Box
                  sx={{
                    p: 1
                  }}
                >
                  <Typography variant="h6">SIGHTSEEING & GUIDED TOURS</Typography>
                </Box>
                <Box
                  sx={{
                    p: 1
                  }}
                >
                  <Typography variant="body2">
                    Immerse yourself in captivating sights and enriching experiences with our
                    expertly guided tours. Discover iconic landmarks, hidden gems, and cultural
                    wonders as our knowledgeable guides unveil the stories behind each destination.
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <Box sx={{ width: '100%' }}>
              <Stack direction="column" alignItems="flex-start" justifyContent="flex-start">
                <Box
                  component="img"
                  src="https://cdn.scalehorizon.com/assets/images/home/PRIVATEJETS.jpg"
                  sx={{ width: '100%', height: '50vh', objectFit: 'cover' }}
                />
                <Box
                  sx={{
                    p: 1
                  }}
                >
                  <Typography variant="h6">PRIVATE JETS AND HELICOPTERS</Typography>
                </Box>
                <Box
                  sx={{
                    p: 1
                  }}
                >
                  <Typography variant="body2">
                    Soar to new heights of luxury and convenience with our exclusive private jet
                    services. Experience seamless travel and unparalleled comfort as you embark on
                    unforgettable journeys around the globe.
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <Box sx={{ width: '100%' }}>
              <Stack direction="column" alignItems="flex-start" justifyContent="flex-start">
                <Box
                  component="img"
                  src="https://cdn.scalehorizon.com/assets/images/home/SHOPPING.jpg"
                  sx={{ width: '100%', height: '50vh', objectFit: 'cover' }}
                />
                <Box
                  sx={{
                    p: 1
                  }}
                >
                  <Typography variant="h6">EXCLUSIVE SHOPPING EXPERIENCES</Typography>
                </Box>
                <Box
                  sx={{
                    p: 1
                  }}
                >
                  <Typography variant="body2">
                    Indulge in unparalleled luxury with our exclusive shopping experiences. Discover
                    hidden boutiques, iconic fashion capitals, and personalized VIP service, as we
                    curate unforgettable moments tailored to your discerning taste.
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Box sx={{ mt: 8 }} />
      <Grid container spacing={10} sx={{ pr: 10, pl: 10 }}>
        <Grid item xs={12} md={4}>
          <Card>
            <Box sx={{ width: '100%' }}>
              <Stack direction="column" alignItems="flex-start" justifyContent="flex-start">
                <Box
                  component="img"
                  src="https://cdn.scalehorizon.com/assets/images/home/TICKETS.jpg"
                  sx={{ width: '100%', height: '50vh', objectFit: 'cover' }}
                />
                <Box
                  sx={{
                    p: 1
                  }}
                >
                  <Typography variant="h6">EVENT TICKETS</Typography>
                </Box>
                <Box
                  sx={{
                    p: 1
                  }}
                >
                  <Typography variant="body2">
                    Gain access to the world' s most exclusive sporting events with our coveted
                    tickets. Witness thrilling moments, experience electrifying atmospheres, and
                    create unforgettable memories in the heart of the action.
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <Box sx={{ width: '100%' }}>
              <Stack direction="column" alignItems="flex-start" justifyContent="flex-start">
                <Box
                  component="img"
                  src="https://cdn.scalehorizon.com/assets/images/home/CHAUFFEURING.jpg"
                  sx={{ width: '100%', height: '50vh', objectFit: 'cover' }}
                />
                <Box
                  sx={{
                    p: 1
                  }}
                >
                  <Typography variant="h6">VIP MEET AND GREET & CHAUFFEURING</Typography>
                </Box>
                <Box
                  sx={{
                    p: 1
                  }}
                >
                  <Typography variant="body2">
                    Experience the red carpet treatment with our VIP Meet and Greet services. Enjoy
                    seamless arrivals, personalized assistance, and exclusive access, as we elevate
                    your travel experience to new heights of luxury and comfort.
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <Box sx={{ width: '100%' }}>
              <Stack direction="column" alignItems="flex-start" justifyContent="flex-start">
                <Box
                  component="img"
                  src="https://cdn.scalehorizon.com/assets/images/home/ACCOMMODATION.jpg"
                  sx={{ width: '100%', height: '50vh', objectFit: 'cover' }}
                />
                <Box
                  sx={{
                    p: 1
                  }}
                >
                  <Typography variant="h6">LUXURY ACCOMMODATION</Typography>
                </Box>
                <Box
                  sx={{
                    p: 1
                  }}
                >
                  <Typography variant="body2">
                    List your offers, promos, or special membership privileges and perks here to
                    entice people to book your property.
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Box sx={{ mt: 8 }} />
    </>
  );
}
