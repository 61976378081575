import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import { axiosInstance_searchActivityEngine } from '../../utils/axios';
import { ActivityState } from '../../@types/activity';
// ----------------------------------------------------------------------

const initialState: ActivityState = {
  isLoading: false,
  error: false,
  activityDetails: {},
  activityList: [],
  insertStatus: null,
  sortBy: null,
  searchCriteria: null,
  userSelection: null
};

const slice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.activityList = [];
    },

    getActivityResultsSuccess(state, action) {
      state.isLoading = false;
      state.activityList = action.payload;
    },

    getActivityDetailsResultsSuccess(state, action) {
      state.isLoading = false;
      state.activityDetails = action.payload;
    },

    setUserActivitySelectionSuccess(state, action) {
      state.isLoading = false;
      state.userSelection = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getActivityResults(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        headers: { 'content-type': 'application/json' }
      };

      const response = await axiosInstance_searchActivityEngine.post(
        '/GetActivityAvailability',
        JSON.stringify(payload),
        options
      );
      console.log('response', response);

      dispatch(slice.actions.getActivityResultsSuccess(response.data.response.Activities));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getActivityDetailsResults(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        headers: { 'content-type': 'application/json' }
      };
      console.log(JSON.stringify(payload));

      const response = await axiosInstance_searchActivityEngine.post(
        '/GetActivityDetails',
        JSON.stringify(payload),
        options
      );

      console.log(response.data.response.Activity);

      dispatch(slice.actions.getActivityDetailsResultsSuccess(response.data.response.Activity));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setUserActivitySelection(payload: any) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setUserActivitySelectionSuccess(payload));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
