import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function ToggleButton(theme: Theme) {
  return {
    MuiToggleButton: {
      styleOverrides: {
        label: {
          width: '100%', // Ensure the correct width for iOS Safari
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        },
        root: {
          padding: '10px 10px',
          wordBreak: 'break-word'
          /* ,
          color: theme.palette.grey[500],
          border: `solid 1px ${theme.palette.grey[500_32]}`,
          '&.Mui-selected': {
            color: theme.palette.grey[isLight ? 600 : 0],
            backgroundColor: theme.palette.action.selected
          },
          '&.Mui-disabled': {
            color: theme.palette.grey[500_48]
          } */
        }
      }
    }
  };
}
