import { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Stack,
  FormGroup,
  FormControlLabel,
  TextField,
  Switch,
  IconButton,
  OutlinedInput,
  Typography
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useSnackbar } from 'notistack';
import InputAdornment from '@mui/material/InputAdornment';
import { MButton } from '../../components/@material-extend';
import { EntityTransactionCriteria, EntityKeyValue } from '../../@types/entity';
import { UpdateEntity, InsertEntity, GetUserByTypeId } from '../../utils/entityapi';
import UserGroupPicker from '../../components/Booking/UserGroupPicker';
import axios from '../../utils/axios';
import { yuphelper } from '../../utils/yuphelper';

const validationSchema = {
  Password: yuphelper.STR_REQ,
  UserGroup: yuphelper.IN_CODE3_REQ
};

export type ClientCreditPickerProps = {
  clientCode: string;
};

export default function ClientCreditPicker({ clientCode }: ClientCreditPickerProps) {
  const [creditLimit, setCreditLimit] = useState<any>();

  useEffect(() => {
    const payload = {
      ClientCode: clientCode
    };

    axios.post(`GetClientCredit`, payload).then((res) => {
      setCreditLimit(res.data.response);
    });
  }, []);

  return (
    <Grid container margin={2}>
      <Grid item xs={8}>
        <Typography variant="h6">Credit Details</Typography>
      </Grid>
      {creditLimit && (
        <Grid container sx={{ m: 0.5 }} spacing={1}>
          <Grid item xs={8}>
            <Typography variant="body1">Total Credit Limit</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">
              {creditLimit.Currency && creditLimit.Currency.Code} {creditLimit.CreditAmount}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1">Total Invoiced</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">
              {creditLimit.Currency && creditLimit.Currency.Code} {creditLimit.InvoicedAmount}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1">Balance Available</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">
              {creditLimit.Currency && creditLimit.Currency.Code} {creditLimit.BalanceAmount}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
