import * as Yup from 'yup';
import { EntityInterface } from '../EntityInterface';
import { EntityFormInterface } from '../EntityFormInterface';
import { PATH_DASHBOARD } from '../../routes/paths';
import { yuphelper } from '../../utils/yuphelper';

const EntityYupSchema = {
  Code: yuphelper.CODE3_REQ,
  Name: yuphelper.STR_REQ,
  BelongsTo: Yup.object({
    BO: Yup.boolean(),
    FO: Yup.boolean(),
    B2C: Yup.boolean(),
    ALL: Yup.boolean()
  }).test('OK', 'Please select at least one.', (a) => (a.BO || a.FO || a.B2C || a.ALL) as boolean)
};

const getFormikInitialValuesHandler = (entity: any) =>
  entity
    ? {
        Code: entity.Code,
        Name: entity.Name,
        BelongsTo: entity.BelongsTo
      }
    : {
        Code: '',
        Name: '',
        BelongsTo: { BO: false, FO: false, B2C: false, ALL: true }
      };

export const DepartmentForm: EntityFormInterface = {
  title: 'Department',
  moduleName: 'Master',
  entityName: 'Department',
  entityType: 'SELF',
  entityYupSchema: EntityYupSchema,
  getFormikInitialValues: getFormikInitialValuesHandler,
  elementGroups: [
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Code',
          field: 'Code',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        },
        {
          label: 'Name',
          field: 'Name',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'BelongsTo',
          field: 'BelongsTo',
          type: 'array',
          control: 'checkboxgroup',
          width: 100,
          values: [
            {
              label: 'Back Office',
              field: 'BO',
              value: 'BO'
            },
            {
              label: 'Front Office',
              field: 'FO',
              value: 'FO'
            },
            {
              label: 'B2C Office',
              field: 'B2C',
              value: 'B2C'
            },
            {
              label: 'All',
              field: 'ALL',
              value: 'ALL'
            }
          ]
        }
      ]
    }
  ]
};

export const Department: EntityInterface = {
  title: 'Department',
  moduleName: 'Master',
  entityName: 'Department',
  entityType: 'SELF',
  entityForm: DepartmentForm,
  sortField: 'Name',
  sortOption: 'asc',
  filterField: 'Name',
  rowsPerPage: 5,
  filterOption: 'contains',
  isDense: false,
  totalRecords: 25,
  tables: [
    {
      field: '_id',
      label: '_id',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: false
    },
    {
      field: 'Code',
      label: 'Code',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'Name',
      label: 'Name',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'BelongsTo',
      label: 'BelongsTo',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'CreatedBy',
      label: 'CreatedBy',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'CreatedDate',
      label: 'CreatedDate',
      alignRight: false,
      type: 'date',
      format: 'DD MMM YYYY HH:SS',
      width: 120,
      filter: false
    },
    {
      field: 'ModifiedBy',
      label: 'ModifiedBy',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'ModifiedDate',
      label: 'ModifiedDate',
      alignRight: false,
      type: 'date',
      format: 'DD MMM YYYY HH:SS',
      width: 120,
      filter: false
    }
  ],
  headerBreadCrumbs: [
    { name: 'Dashboard', href: PATH_DASHBOARD.root },
    { name: 'Masters', href: PATH_DASHBOARD.masters.root },
    { name: 'Department', href: PATH_DASHBOARD.masters.department },
    { name: 'List' }
  ]
};

export default Department;
