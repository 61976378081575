import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import { axiosInstance_searchEngine, axiosInstance_searchActivityEngine } from '../../utils/axios';
import { HotelState, HotelSearchCriteria, UserHotelSelection } from '../../@types/hotel';
// ----------------------------------------------------------------------

const initialState: HotelState = {
  isLoading: false,
  error: false,
  hotelDetails: {},
  hotelPackageDetails: {},
  hotelList: [],
  insertStatus: null,
  sortBy: null,
  searchCriteria: null,
  userSelection: null
};

const slice = createSlice({
  name: 'hotel',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.hotelList = [];
    },

    setReset(state) {
      state.hotelDetails = {};
      // state.hotelList = [];
    },

    getHotelResultsSuccess(state, action) {
      state.isLoading = false;
      state.hotelList = action.payload;
    },

    getHotelDetailsResultsSuccess(state, action) {
      state.isLoading = false;
      state.hotelDetails = action.payload;
    },

    getHotelPackageDetailsResultsSuccess(state, action) {
      state.isLoading = false;
      state.hotelPackageDetails = action.payload;
    },

    setUserHotelSelectionSuccess(state, action) {
      state.isLoading = false;
      state.userSelection = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getHotelResults(payload: HotelSearchCriteria) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        headers: { 'content-type': 'application/json' }
      };

      const response = await axiosInstance_searchEngine.post(
        '/GetHotelAvailability',
        JSON.stringify(payload),
        options
      );

      dispatch(slice.actions.getHotelResultsSuccess(response.data.response.Hotels));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getHotelDetailsResults(payload: HotelSearchCriteria) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        headers: { 'content-type': 'application/json' }
      };

      const response = await axiosInstance_searchEngine.post(
        '/GetHotelAvailabilityDetails',
        JSON.stringify(payload),
        options
      );

      dispatch(slice.actions.getHotelDetailsResultsSuccess(response.data.response.HotelDetails));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getHotelPackageDetailsResults(payload: HotelSearchCriteria) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        headers: { 'content-type': 'application/json' }
      };

      const response = await axiosInstance_searchActivityEngine.post(
        '/GetActivitySchedule',
        JSON.stringify(payload),
        options
      );

      dispatch(slice.actions.getHotelPackageDetailsResultsSuccess(response.data.response));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setUserHotelSelection(payload: UserHotelSelection) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setUserHotelSelectionSuccess(payload));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetHotelDetails() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setReset());
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
