import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Link(theme: Theme) {
  return {
    MuiLink: {
      styleOverrides: {
        root: {
          '&[disabled]': {
            color: 'grey',
            cursor: 'default',
            '&:hover': {
              textDecoration: 'none'
            }
          }
        }
      }
    }
  };
}
