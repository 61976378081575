// routes
import { Router, RouterB2B, RouterB2C } from './routes';
// theme
import ThemeConfig from './theme';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import { engineType } from './config';
// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <RtlLayout>
          <NotistackProvider>
            <Settings />
            <ScrollToTop />
            {engineType === 'B2C' && <GoogleAnalytics />}
            {engineType === 'BO' && <Router />}
            {engineType === 'B2B' && <RouterB2B />}
            {engineType === 'B2C' && <RouterB2C />}
          </NotistackProvider>
        </RtlLayout>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
