import { m } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
// import { capitalCase } from 'change-case';
import { Link as RouterLink, useLocation, Outlet } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Container, Typography, Grid } from '@mui/material';
// routes
import { PATH_AUTH } from '../routes/paths';
// hooks
// import useAuth from '../../hooks/useAuth';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
import MainFooter from '../layouts/B2B/MainFooter';
import MainHeader from '../layouts/B2B/MainHeader';
import MainDeals from '../layouts/B2B/MainDeals';
import { endPoints } from '../config';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 64;

const getCompanyDomain = (hostName: string) => {
  if (hostName === 'localhost') {
    return 'ScaleHorizon';
  }

  const subDomain = hostName.split('.');
  if (subDomain[0] === 'backoffice') {
    return 'ScaleHorizon';
  } else if (subDomain[0] === 'oceandusk') {
    return 'OceanDusk';
  }
};

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 20,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 20,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%'
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const HeroOverlayStyle = styled(m.img)({
  zIndex: 9,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

const HeroImgStyle = styled(m.img)(({ theme }) => ({
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 8,
  width: '100%',
  margin: 'auto',
  position: 'absolute',
  [theme.breakpoints.up('lg')]: {
    right: '8%',
    width: 'auto',
    height: '48vh'
  }
}));

// ----------------------------------------------------------------------

export default function TermsAndConditions() {
  // const { method } = useAuth();
  const navigate = useNavigate();
  const endPoint =
    process.env.NODE_ENV === 'production' ? endPoints.prodCDNEndPoint : endPoints.prodCDNEndPoint;

  return (
    <>
      <Box sx={{ mt: 8 }} />
      <Grid container spacing={10} sx={{ pr: 10, pl: 10 }} id="terms">
        <Grid item xs={12} md={6}>
          <Card>
            <Box sx={{ width: '100%' }}>
              <Stack direction="column" alignItems="flex-start" justifyContent="flex-start">
                <Box
                  sx={{
                    pl: 5,
                    pr: 5
                  }}
                >
                  <Typography variant="h3">TERMS & CONDITIONS</Typography>
                </Box>
                <Box
                  sx={{
                    p: 5
                  }}
                >
                  <Typography variant="h6">Terms and Conditions:</Typography>
                  <Box
                    sx={{
                      mt: 2
                    }}
                  ></Box>
                  <Typography variant="subtitle1">
                    These terms are the terms of use on which you may make use of our website under
                    the domain name: www.odos.travel whether as a guest or a registered user. Please
                    read these terms of use carefully before starting to use the website. By using
                    our website, you indicate that you accept these terms of use and that you agree
                    to abide by them, regardless of whether or not you choose to register with us.
                    If you do not agree to these terms of use, please refrain from using our
                    website.
                  </Typography>
                  <Box
                    sx={{
                      mt: 2
                    }}
                  ></Box>
                  <Typography variant="h6">Refund Policy:</Typography>
                  <Box
                    sx={{
                      mt: 2
                    }}
                  ></Box>
                  <Typography variant="subtitle1">
                    Refunds are subject to the specific cancellation/no show policy applicable to
                    the booking and notified to you at the time the booking is made. Booking
                    cancellations/no show may incur cancellation charges. Cancellations received
                    after the time limit will be subject to a “client no show policy”. Where you are
                    permitted to cancel your booking, Oceandusk will process refund due to you after
                    deducting the cancellation/no show charges as soon as possible and, in any case,
                    within fourteen (14) days of the day on which you gave us notice of
                    cancellation. Cancellation notification must be sent to the e- mail address from
                    which you received your booking confirmation E-mail. For reduced nights and
                    early check-outs, you should not refund to clients unless authority has been
                    given by Oceandusk. Refunds must be advised within 14 days after check-out,
                    after this period no refund will be entertained. Refunds are also subject to
                    cancellation/no show charges.
                  </Typography>
                  <Box
                    sx={{
                      mt: 2
                    }}
                  ></Box>
                  <Typography variant="h6">Privacy Policy:</Typography>
                  <Box
                    sx={{
                      mt: 2
                    }}
                  ></Box>
                  <Typography variant="subtitle1">
                    Although the main purpose of this website is to provide information about our
                    company and no services or products are provided or sold directly via this
                    website, we may collect and process some personal data and information about the
                    visitors to our website and use such information as described in this section
                    (“Privacy Policy ”). By accessing, visiting, and using this website you agree
                    with the provisions of this section and to collection, processing, transfer,
                    store, or disclosure by us of your information in accordance with this Privacy
                    Policy. Unfortunately, the transmission of information via Internet is not
                    completely secure. We cannot guarantee the security of your data transmitted to
                    our website, and any such transmission is at your own risk, Once we have
                    received your information, we will use strict procedures and security features
                    to try to prevent unauthorized access.
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <Box sx={{ width: '100%', mt: 2 }}>
              <Stack direction="column" alignItems="flex-start" justifyContent="flex-start">
                <Box
                  component="img"
                  src="https://cdn.scalehorizon.com/assets/images/home/CONTRACT.jpg"
                  sx={{ width: '100%', height: '50vh', objectFit: 'cover' }}
                />
              </Stack>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
