import { PATH_DASHBOARD } from '../../routes/paths';
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  sort: getIcon('ic_sort'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  legends: getIcon('ic_legends'),
  masters: getIcon('ic_autoawesomemotion'),
  wizards: getIcon('ic_autoawesomemosaic'),
  bookings: getIcon('ic_apps'),
  accounts: getIcon('ic_accounts')
};

const getRights = (moduleName: string, userRights: any): any => {
  const rights =
    userRights && userRights.Rights.find((element: any) => element.ModuleCode === moduleName);
  return rights;
};

const getWizardsBOLinks = (userRights: any): any => {
  let links: any = {
    subheader: 'Wizards',
    items: [
      {
        title: 'Client Consultants',
        path: PATH_DASHBOARD.wizards.root,
        icon: ICONS.wizards,
        children: [
          getRights('Client', userRights).Read && {
            title: 'Client Consultants',
            path: PATH_DASHBOARD.wizards.clientemployee
          }
        ]
      }
    ]
  };

  const children = links && links.items[0].children.filter((item: any) => item !== false);
  links && children && children.length > 0
    ? (links.items[0].children = children)
    : (links = undefined);
  return links;
};

export function getAgentsSidebarConfig(userRights: any) {
  if (!userRights) {
    return [];
  }

  const agentsSidebarConfig = [
    {
      subheader: 'Book',
      items: [
        {
          title: 'Book',
          path: PATH_DASHBOARD.booking.root,
          icon: ICONS.ecommerce,
          children: [{ title: 'New Bookings', path: PATH_DASHBOARD.booking.searchengine }]
        }
      ]
    },
    {
      subheader: 'Manage Bookings',
      items: [
        {
          title: 'Bookings',
          path: PATH_DASHBOARD.managebookings.root,
          icon: ICONS.bookings,
          children: [{ title: 'Find Bookings', path: PATH_DASHBOARD.managebookings.manageBooking }]
        }
      ]
    },
    {
      subheader: 'Accounts',
      items: [
        {
          title: 'Accounts',
          path: PATH_DASHBOARD.accounts.root,
          icon: ICONS.accounts,
          children: [
            { title: 'Payments Received', path: PATH_DASHBOARD.accounts.payments },
            { title: 'Invoices', path: PATH_DASHBOARD.accounts.invoices }
          ]
        }
      ]
    },
    {
      subheader: 'Reports',
      items: [
        {
          title: 'Reports',
          path: PATH_DASHBOARD.reports.root,
          icon: ICONS.dashboard,
          children: [
            { title: 'BI Report', path: PATH_DASHBOARD.reports.warehouse },
            { title: 'Client Tariff', path: PATH_DASHBOARD.reports.clienttariffreport },
            { title: 'Tariff Notifications', path: PATH_DASHBOARD.reports.tariffnotifications }
          ]
        }
      ]
    }
  ];

  const wizardsLink = getWizardsBOLinks(userRights);
  wizardsLink && agentsSidebarConfig.push(wizardsLink);

  return agentsSidebarConfig;
}

export default getAgentsSidebarConfig;
