import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import { axiosInstance_searchFareEngine } from '../../utils/axios';
import { FareState, FareSearchCriteria } from '../../@types/fare';

// ----------------------------------------------------------------------

const initialState: FareState = {
  isLoading: false,
  error: false,
  message: '',
  fareList: undefined,
  sortBy: null
};

const slice = createSlice({
  name: 'fare',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.fareList = undefined;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = true;
      state.message = action.payload;
      state.fareList = undefined;
    },

    getFareResultsSuccess(state, action) {
      state.isLoading = false;
      state.fareList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getFareResults(payload: FareSearchCriteria) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        headers: { 'content-type': 'application/json' }
      };

      const response = await axiosInstance_searchFareEngine.post(
        '/GetFareAvailability',
        JSON.stringify(payload),
        options
      );

      !response.data.status.Status &&
        dispatch(slice.actions.hasError(response.data.status.Status.Message));
      response.data.status.Status &&
        dispatch(slice.actions.getFareResultsSuccess(response.data.response));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
