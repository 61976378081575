import * as Yup from 'yup';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Alert,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from '../../../utils/axios';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
//
import { MIconButton } from '../../@material-extend';
import { engineType } from '../../../config';

// ----------------------------------------------------------------------

export default function ForgetPasswordForm() {
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required')
  });

  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    setError,
    getValues,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: { email: '' },
    resolver: yupResolver(LoginSchema)
  });

  const onSubmit = async (data: any) => {
    // async (data, reset) => {
    try {
      const resetLink: any = {
        EmailId: data.email
      };

      axios.post(`SendResetLink`, resetLink).then((res) => {
        if (res.data.response.Status) {
          enqueueSnackbar(`Updated Successfully`, {
            variant: 'success'
          });
          setError('email', { type: 'manual', message: 'Please check the email.' });
        } else {
          enqueueSnackbar(`Failed to reset the password`, {
            variant: 'error'
          });

          setError('email', { type: 'manual', message: 'Failed to reset the password' });
        }
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(`Failed to reset the password`, {
        variant: 'error'
      });

      reset({ email: '' });
      setError('email', { type: 'manual', message: 'Failed to reset the password' });
      // if (isMountedRef.current) {
      // setSubmitting(false);
      // }
    }
  };
  // };

  return (
    <>
      <Stack spacing={2} padding={2}>
        <Typography variant="h4">Reset your password</Typography>
        <Typography variant="subtitle1">
          Enter your email, and we’ll send you a link to reset your password.
        </Typography>
        <TextField
          fullWidth
          autoComplete="username"
          type="email"
          label="Email address"
          {...register('email')}
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
        />

        {errors.email && <Alert severity="error">{errors.email?.message}</Alert>}

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          Send Reset Link
        </LoadingButton>
      </Stack>
    </>
  );
}
