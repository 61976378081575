import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  sort: getIcon('ic_sort'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  legends: getIcon('ic_legends'),
  masters: getIcon('ic_autoawesomemotion'),
  wizards: getIcon('ic_autoawesomemosaic'),
  bookings: getIcon('ic_apps'),
  accounts: getIcon('ic_accounts')
};

const getRights = (moduleName: string, userRights: any): any => {
  const rights =
    userRights && userRights.Rights.find((element: any) => element.ModuleCode === moduleName);
  return rights;
};

const getBookingBOLinks = (userRights: any): any => {
  let links: any = {
    subheader: 'Book',
    items: [
      {
        title: 'Book',
        path: PATH_DASHBOARD.booking.root,
        icon: ICONS.ecommerce,
        children: [
          getRights('Book', userRights).Read && {
            title: 'New Bookings',
            path: PATH_DASHBOARD.booking.searchengine
          },
          getRights('IMF', userRights).Read && {
            title: 'Import Fares',
            path: PATH_DASHBOARD.booking.importpnr
          },
          getRights('MAB', userRights).Read && {
            title: 'Manual Booking',
            path: PATH_DASHBOARD.booking.manualbooking
          },
          getRights('Book', userRights).Read && {
            title: 'Yearly Hotel Search',
            path: PATH_DASHBOARD.booking.yearlysearch
          },
          getRights('Book', userRights).Read && {
            title: 'Debug Hotel Search',
            path: PATH_DASHBOARD.booking.debugsearch
          }
        ]
      }
    ]
  };

  const children = links && links.items[0].children.filter((item: any) => item !== false);
  links && children && children.length > 0
    ? (links.items[0].children = children)
    : (links = undefined);
  return links;
};

const getManageBookingBOLinks = (userRights: any): any => {
  const rights = userRights.Rights.find((item: any) => item.ModuleCode === 'ManBook');

  const links: any = rights.Read
    ? {
        subheader: 'Manage Bookings',
        items: [
          {
            title: 'Bookings',
            path: PATH_DASHBOARD.managebookings.root,
            icon: ICONS.bookings,
            children: [
              { title: 'Find Bookings', path: PATH_DASHBOARD.managebookings.manageBooking }
            ]
          }
        ]
      }
    : undefined;

  return links;
};

const getWizardsBOLinks = (userRights: any): any => {
  let links: any = {
    subheader: 'Wizards',
    items: [
      {
        title: 'Wizards',
        path: PATH_DASHBOARD.wizards.root,
        icon: ICONS.wizards,
        children: [
          getRights('Activity', userRights).Read && {
            title: 'Activity',
            path: PATH_DASHBOARD.wizards.activity
          },
          getRights('Client', userRights).Read && {
            title: 'Client',
            path: PATH_DASHBOARD.wizards.client
          },
          getRights('Client', userRights).Read && {
            title: 'Client Consultants',
            path: PATH_DASHBOARD.wizards.clientemployee
          },
          getRights('Tariff', userRights).Read && {
            title: 'Client Tariff',
            path: PATH_DASHBOARD.wizards.clienttariff
          },
          getRights('Employee', userRights).Read && {
            title: 'Employee',
            path: PATH_DASHBOARD.wizards.employee
          },
          getRights('FTM', userRights).Read && {
            title: 'Flight Markup',
            path: PATH_DASHBOARD.wizards.flightmarkup
          },
          getRights('Hotel', userRights).Read && {
            title: 'Hotel',
            path: PATH_DASHBOARD.wizards.hotel
          },
          getRights('Hotel', userRights).Read && {
            title: 'Hotel Allocations',
            path: PATH_DASHBOARD.wizards.hotelallocation
          },
          getRights('HTM', userRights).Read && {
            title: 'Hotel Thidparty Markup',
            path: PATH_DASHBOARD.wizards.hotelthirdpartymarkup
          },
          getRights('UGP', userRights).Read && {
            title: 'User Group',
            path: PATH_DASHBOARD.wizards.usergroup
          }
        ]
      }
    ]
  };

  const children = links && links.items[0].children.filter((item: any) => item !== false);
  links && children && children.length > 0
    ? (links.items[0].children = children)
    : (links = undefined);
  return links;
};

const getMastersBOLinks = (userRights: any): any => {
  const rights = userRights.Rights.find((item: any) => item.ModuleCode === 'Master');

  let links: any = rights.Read
    ? {
        subheader: 'Masters',
        items: [
          {
            title: 'Masters',
            path: PATH_DASHBOARD.masters.root,
            icon: ICONS.masters,
            children: [
              { title: 'Client Group', path: PATH_DASHBOARD.masters.clientgroup },
              getRights('CurEx', userRights).Read && {
                title: 'Currency Exchange',
                path: PATH_DASHBOARD.masters.currencyexchange
              },
              { title: 'Department', path: PATH_DASHBOARD.masters.department },
              { title: 'Designation', path: PATH_DASHBOARD.masters.designation },
              getRights('DisType', userRights).Read && {
                title: 'DiscountType',
                path: PATH_DASHBOARD.masters.discounttype
              },
              { title: 'Market', path: PATH_DASHBOARD.masters.market },
              { title: 'Product', path: PATH_DASHBOARD.masters.product },
              { title: 'Supplement', path: PATH_DASHBOARD.masters.supplement },
              { title: 'Supplier', path: PATH_DASHBOARD.masters.supplier },
              getRights('Tariff', userRights).Read && {
                title: 'Tariff',
                path: PATH_DASHBOARD.masters.tariff
              },
              getRights('Vat', userRights).Read && {
                title: 'Vat',
                path: PATH_DASHBOARD.masters.vat
              }
            ]
          }
        ]
      }
    : undefined;

  const children = links && links.items[0].children.filter((item: any) => item !== false);
  links ? (links.items[0].children = children) : (links = undefined);
  return links;
};

const getLegendsBOLinks = (userRights: any): any => {
  const rights = userRights.Rights.find((item: any) => item.ModuleCode === 'Legend');

  const links: any = rights.Read
    ? {
        subheader: 'Legends',
        items: [
          {
            title: 'Legends',
            path: PATH_DASHBOARD.legends.root,
            icon: ICONS.legends,
            children: [
              { title: 'Accommodation Type', path: PATH_DASHBOARD.legends.accommodationtype },
              { title: 'Activity Category', path: PATH_DASHBOARD.legends.activitycategory },
              { title: 'Activity SubCategory', path: PATH_DASHBOARD.legends.activitysubcategory },
              { title: 'Airport', path: PATH_DASHBOARD.legends.airport },
              { title: 'City', path: PATH_DASHBOARD.legends.city },
              { title: 'Country', path: PATH_DASHBOARD.legends.country },
              { title: 'Currency', path: PATH_DASHBOARD.legends.currency },
              { title: 'Destination', path: PATH_DASHBOARD.legends.destination },
              { title: 'Facility', path: PATH_DASHBOARD.legends.facility },
              { title: 'Hotel Category', path: PATH_DASHBOARD.legends.category },
              { title: 'Language', path: PATH_DASHBOARD.legends.language },
              { title: 'Modules', path: PATH_DASHBOARD.legends.module }
            ]
          }
        ]
      }
    : undefined;

  return links;
};

const getAccountsBOLinks = (userRights: any): any => {
  const rights = userRights.Rights.find((item: any) => item.ModuleCode === 'Account');

  const links: any = rights.Read
    ? {
        subheader: 'Accounts',
        items: [
          {
            title: 'Accounts',
            path: PATH_DASHBOARD.accounts.root,
            icon: ICONS.accounts,
            children: [
              { title: 'Payments Received', path: PATH_DASHBOARD.accounts.payments },
              { title: 'Invoices', path: PATH_DASHBOARD.accounts.invoices },
              { title: 'Vcn Funding Accounts', path: PATH_DASHBOARD.accounts.carddetails },
              { title: 'Vcn List', path: PATH_DASHBOARD.accounts.vcntransactions }
            ]
          }
        ]
      }
    : undefined;

  return links;
};

const getReportsBOLinks = (userRights: any): any => {
  const rights = userRights.Rights.find((item: any) => item.ModuleCode === 'Report');

  const links: any = rights.Read
    ? {
        subheader: 'Reports',
        items: [
          {
            title: 'Reports',
            path: PATH_DASHBOARD.reports.root,
            icon: ICONS.dashboard,
            children: [
              { title: 'Activities Preparation', path: PATH_DASHBOARD.reports.activitypreparation },
              { title: 'Hotel Preparation', path: PATH_DASHBOARD.reports.hotelpreparation },
              { title: 'Allocation utilisation', path: PATH_DASHBOARD.wizards.hotelallocation },
              { title: 'BI Report', path: PATH_DASHBOARD.reports.warehouse },
              { title: 'Client Tariff', path: PATH_DASHBOARD.reports.clienttariffreport },
              { title: 'Tariff Notifications', path: PATH_DASHBOARD.reports.tariffnotifications },
              { title: 'Audit Trail', path: PATH_DASHBOARD.reports.audittrail },
              { title: 'Login Trail', path: PATH_DASHBOARD.reports.logintrail }
            ]
          }
        ]
      }
    : undefined;

  return links;
};

export function getSidebarConfig(userRights: any) {
  if (!userRights) {
    return [];
  }

  const sidebarConfig: any[] = [];

  const bookingLink = getBookingBOLinks(userRights);
  bookingLink && sidebarConfig.push(bookingLink);

  const manageBookingLink = getManageBookingBOLinks(userRights);
  manageBookingLink && sidebarConfig.push(manageBookingLink);

  const wizardsLink = getWizardsBOLinks(userRights);
  wizardsLink && sidebarConfig.push(wizardsLink);

  const mastersLink = getMastersBOLinks(userRights);
  mastersLink && sidebarConfig.push(mastersLink);

  const legendsLink = getLegendsBOLinks(userRights);
  legendsLink && sidebarConfig.push(legendsLink);

  const accountsLink = getAccountsBOLinks(userRights);
  accountsLink && sidebarConfig.push(accountsLink);

  const reportsLink = getReportsBOLinks(userRights);
  reportsLink && sidebarConfig.push(reportsLink);

  return sidebarConfig;
}

export default getSidebarConfig;
