import { Box, Grid, Stack, Typography, Card } from '@mui/material';
import ServiceDeals from './ServiceDeals';
// ----------------------------------------------------------------------

export default function MainDeals() {
  return (
    <>
      <Box sx={{ mt: 8 }} />
      <Grid container spacing={10} sx={{ pr: 10, pl: 10 }} id="aboutus">
        <Grid item xs={12} md={6}>
          <Card>
            <Box sx={{ width: '100%' }}>
              <Stack direction="column" alignItems="flex-start" justifyContent="flex-start">
                <Box
                  sx={{
                    pl: 5,
                    pr: 5
                  }}
                >
                  <Typography variant="h3">ABOUT US</Typography>
                </Box>
                <Box
                  sx={{
                    p: 5
                  }}
                >
                  <Typography variant="subtitle1">
                    ODOS stands as a formidable platform meticulously travel experience. your
                    designed to streamline and elevate premier hotel chain, we provide In
                    collaboration with a travel agents and partners with immediate access to an
                    real-time global properties and extensive selection of interface inventory. Our
                    intuitive simplifies the process of viewing, booking, and managing travel
                    arrangements, convenience. offering agents unparalleled efficiency and seamless
                    booking experience for your This translates to a clients. The ongoing expansion
                    of Oceandusk is a commitment harnessing to testament to our unwavering
                    technology for global expansion, ensuring VIP service for our esteemed
                    clientele.
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <Box sx={{ width: '100%' }}>
              <Stack direction="column" alignItems="flex-start" justifyContent="flex-start">
                <Box
                  component="img"
                  src="https://cdn.scalehorizon.com/assets/images/home/content-img-492x466.jpg"
                  sx={{ width: '100%', height: '50vh', objectFit: 'cover' }}
                />
              </Stack>
            </Box>
          </Card>
        </Grid>
      </Grid>

      <ServiceDeals />
    </>
  );
}
