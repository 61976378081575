import * as Yup from 'yup';
import { EntityInterface } from '../EntityInterface';
import { EntityFormInterface } from '../EntityFormInterface';
import { PATH_DASHBOARD } from '../../routes/paths';
import { yuphelper } from '../../utils/yuphelper';

export type FormikInitialValues = {};

const EntityYupSchema = {
  Code: yuphelper.CODE3_REQ,
  FirstName: yuphelper.STR_REQ,
  LastName: yuphelper.STR_REQ,
  Email: yuphelper.EMAIL_REQ
};

const getFormikInitialValuesHandler = (entity: any) =>
  entity
    ? {
        FirstName: entity.FirstName,
        LastName: entity.LastName,
        Code: entity.Code,
        Designation: entity.Designation
          ? { Code: entity.Designation.Code, Name: entity.Designation.Name }
          : { Code: '', Name: '' },
        Department: entity.Department
          ? { Code: entity.Department.Code, Name: entity.Department.Name }
          : { Code: '', Name: '' },
        Address: entity.Address,
        City: entity.City,
        Country: entity.Country
          ? { Code: entity.Country.Code, Name: entity.Country.Name }
          : { Code: '', Name: '' },
        ZipCode: entity.ZipCode,
        Email: entity.Email,
        PhoneNumber: entity.PhoneNumber,
        MobileNumber: entity.MobileNumber,
        Status: entity.Status
      }
    : {
        FirstName: '',
        LastName: '',
        Code: '',
        Designation: { Code: '', Name: '' },
        Department: { Code: '', Name: '' },
        Address: '',
        City: '',
        Country: { Code: '', Name: '' },
        ZipCode: '',
        Email: '',
        PhoneNumber: '',
        MobileNumber: '',
        Status: false
      };

export const EmployeeForm: EntityFormInterface = {
  title: 'Employee',
  moduleName: 'Employee',
  entityName: 'Employee',
  entityType: 'SELF',
  entityYupSchema: EntityYupSchema,
  getFormikInitialValues: getFormikInitialValuesHandler,
  elementGroups: [
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Code',
          field: 'Code',
          type: 'string',
          control: 'textbox',
          width: 15,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'FirstName',
          field: 'FirstName',
          type: 'string',
          control: 'textbox',
          width: 25,
          values: []
        },
        {
          label: 'LastName',
          field: 'LastName',
          type: 'string',
          control: 'textbox',
          width: 25,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Designation',
          field: 'Designation',
          type: 'string',
          control: 'selectserverfill',
          width: 100,
          values: []
        },
        {
          label: 'Department',
          field: 'Department',
          type: 'string',
          control: 'selectserverfill',
          width: 100,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Address',
          field: 'Address',
          type: 'string',
          control: 'textbox',
          width: 150,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Country',
          field: 'Country',
          type: 'string',
          control: 'selectserverfill',
          width: 100,
          values: []
        },
        {
          label: 'City',
          field: 'City',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Email',
          field: 'Email',
          type: 'string',
          control: 'textbox',
          width: 255,
          values: []
        },
        {
          label: 'ZipCode',
          field: 'ZipCode',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'PhoneNumber',
          field: 'PhoneNumber',
          type: 'string',
          control: 'textbox',
          width: 20,
          values: []
        },
        {
          label: 'MobileNumber',
          field: 'MobileNumber',
          type: 'string',
          control: 'textbox',
          width: 20,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Status',
          field: 'Status',
          type: 'boolean',
          control: 'switch',
          width: 100,
          values: []
        }
      ]
    }
  ]
};

export const Employee: EntityInterface = {
  title: 'Employee',
  moduleName: 'Employee',
  entityName: 'Employee',
  entityType: 'SELF',
  entityForm: EmployeeForm,
  sortField: 'Name',
  sortOption: 'asc',
  filterField: 'FirstName',
  rowsPerPage: 5,
  filterOption: 'contains',
  isDense: false,
  totalRecords: 100,
  tables: [
    {
      field: '_id',
      label: '_id',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: false
    },
    {
      field: 'Code',
      label: 'Code',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'FirstName',
      label: 'FirstName',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'LastName',
      label: 'LastName',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'Email',
      label: 'Email',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'PhoneNumber',
      label: 'PhoneNumber',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: '',
      label: 'Passport',
      alignRight: false,
      type: 'Link',
      width: 120,
      filter: true,
      Url: 'Test'
    },
    {
      field: 'CreatedBy',
      label: 'CreatedBy',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'CreatedDate',
      label: 'CreatedDate',
      alignRight: false,
      type: 'date',
      format: 'DD MMM YYYY HH:SS',
      width: 120,
      filter: false
    },
    {
      field: 'ModifiedBy',
      label: 'ModifiedBy',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'ModifiedDate',
      label: 'ModifiedDate',
      alignRight: false,
      type: 'date',
      format: 'DD MMM YYYY HH:SS',
      width: 120,
      filter: false
    }
  ],
  headerBreadCrumbs: [
    { name: 'Dashboard', href: PATH_DASHBOARD.root },
    { name: 'Wizards', href: PATH_DASHBOARD.wizards.root },
    { name: 'Employee', href: PATH_DASHBOARD.wizards.employee },
    { name: 'List' }
  ]
};

export default Employee;
