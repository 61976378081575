import * as Yup from 'yup';
import { EntityInterface } from '../EntityInterface';
import { EntityFormInterface } from '../EntityFormInterface';
import { PATH_DASHBOARD } from '../../routes/paths';
import { yuphelper } from '../../utils/yuphelper';

const EntityYupSchema = {
  Code: yuphelper.CODE3_REQ,
  Name: yuphelper.STR_REQ
};

const getFormikInitialValuesHandler = (entity: any) =>
  entity
    ? {
        Code: entity.Code,
        Name: entity.Name,
        ContactName: entity.ContactName,
        Designation: entity.Designation
          ? { Code: entity.Designation.Code, Name: entity.Designation.Name }
          : { Code: '', Name: '' },
        Department: entity.Department
          ? { Code: entity.Department.Code, Name: entity.Department.Name }
          : { Code: '', Name: '' },
        Address: entity.Address,
        City: entity.City,
        Country: entity.Country
          ? { Code: entity.Country.Code, Name: entity.Country.Name }
          : { Code: '', Name: '' },
        PostCode: entity.PostCode,
        Email: entity.Email,
        Tel: entity.Tel,
        Fax: entity.Fax,
        Mobile: entity.Mobile,
        Currency: entity.Currency,
        CommunicationMethod: entity.CommunicationMethod,
        GroundHandler: entity.GroundHandler,
        CreditSupplier: entity.CreditSupplier,
        CreditDays: entity.CreditDays,
        PaymentOn: entity.PaymentOn,
        DocumentType: entity.DocumentType
      }
    : {
        Code: '',
        Name: '',
        Designation: { Code: '', Name: '' },
        Department: { Code: '', Name: '' },
        Address: '',
        City: '',
        Country: { Code: '', Name: '' },
        PostCode: '',
        Email: '',
        Tel: '',
        Fax: '',
        Mobile: '',
        Currency: { Code: '', Name: '' },
        CommunicationMethod: 'Email',
        GroundHandler: false,
        CreditSupplier: false,
        CreditDays: 0,
        PaymentOn: 'MOS',
        DocumentType: 'V'
      };

export const SupplierForm: EntityFormInterface = {
  title: 'Supplier',
  moduleName: 'Master',
  entityName: 'Supplier',
  entityType: 'SELF',
  entityYupSchema: EntityYupSchema,
  getFormikInitialValues: getFormikInitialValuesHandler,
  elementGroups: [
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Code',
          field: 'Code',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        },
        {
          label: 'Name',
          field: 'Name',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Contact Name',
          field: 'ContactName',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Designation',
          field: 'Designation',
          type: 'string',
          control: 'selectserverfill',
          width: 100,
          values: []
        },
        {
          label: 'Department',
          field: 'Department',
          type: 'string',
          control: 'selectserverfill',
          width: 100,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Address',
          field: 'Address',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Country',
          field: 'Country',
          type: 'string',
          control: 'selectserverfill',
          width: 100,
          values: []
        },
        {
          label: 'City',
          field: 'City',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'PostCode',
          field: 'PostCode',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        },
        {
          label: 'Email',
          field: 'Email',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Tel',
          field: 'Tel',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        },
        {
          label: 'Fax',
          field: 'Fax',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Mobile',
          field: 'Mobile',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Currency',
          field: 'Currency',
          type: 'string',
          control: 'selectserverfill',
          width: 100,
          values: []
        },
        {
          label: 'CommunicationMethod',
          field: 'CommunicationMethod',
          type: 'string',
          control: 'select',
          values: [
            {
              label: 'Email',
              value: 'Email'
            },
            {
              label: 'Fax',
              value: 'Fax'
            }
          ],
          width: 100
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'GroundHandler',
          field: 'GroundHandler',
          type: 'boolean',
          control: 'switch',
          width: 100,
          values: []
        },
        {
          label: 'CreditSupplier',
          field: 'CreditSupplier',
          type: 'boolean',
          control: 'switch',
          width: 100,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'PaymentOn',
          field: 'PaymentOn',
          type: 'string',
          control: 'select',
          values: [
            {
              label: 'Month of stay',
              value: 'MOS'
            },
            {
              label: 'Month of departure',
              value: 'MOD'
            }
          ],
          width: 100
        },
        {
          label: 'DocumentType',
          field: 'DocumentType',
          type: 'string',
          control: 'select',
          values: [
            {
              label: 'E-Itinerary',
              value: 'I'
            },
            {
              label: 'Travel Voucher',
              value: 'V'
            },
            {
              label: 'NoShow',
              value: 'N'
            }
          ],
          width: 100
        }
      ]
    }
  ]
};

export const Supplier: EntityInterface = {
  title: 'Supplier',
  moduleName: 'Master',
  entityName: 'Supplier',
  entityType: 'SELF',
  entityForm: SupplierForm,
  sortField: 'Name',
  sortOption: 'asc',
  filterField: 'Name',
  rowsPerPage: 5,
  filterOption: 'contains',
  isDense: false,
  totalRecords: 10,
  tables: [
    {
      field: '_id',
      label: '_id',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: false
    },
    {
      field: 'Code',
      label: 'Code',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'Name',
      label: 'Name',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'Country',
      label: 'Country',
      alignRight: false,
      type: 'KeyValue',
      width: 120,
      filter: true
    },
    {
      field: 'Currency',
      label: 'Currency',
      alignRight: false,
      type: 'KeyValue',
      width: 120,
      filter: true
    },
    {
      field: 'CommunicationMethod',
      label: 'CommunicationMethod',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'CreatedBy',
      label: 'CreatedBy',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'CreatedDate',
      label: 'CreatedDate',
      alignRight: false,
      type: 'date',
      format: 'DD MMM YYYY HH:SS',
      width: 120,
      filter: false
    },
    {
      field: 'ModifiedBy',
      label: 'ModifiedBy',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'ModifiedDate',
      label: 'ModifiedDate',
      alignRight: false,
      type: 'date',
      format: 'DD MMM YYYY HH:SS',
      width: 120,
      filter: false
    }
  ],
  headerBreadCrumbs: [
    { name: 'Dashboard', href: PATH_DASHBOARD.root },
    { name: 'Masters', href: PATH_DASHBOARD.masters.root },
    { name: 'Supplier', href: PATH_DASHBOARD.masters.supplier },
    { name: 'List' }
  ]
};

export default Supplier;
