import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function ToggleButtonGroup(theme: Theme) {
  return {
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          spacing: '15px 15px'
        }
      }
    }
  };
}
