import axios from 'axios';
import { setSession } from './jwt';
import { endPoints, engineType } from '../config';
// ----------------------------------------------------------------------

// BO
export const axiosInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production' ? endPoints.prodBOEndPoint : endPoints.devBOEndPoint,
  headers: {
    post: {
      'Content-Type': 'application/json'
    }
  }
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.headers.client) {
      const token = response.headers.Token;
      setSession(token);
    }

    return response;
  },
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

axiosInstance.interceptors.request.use((config) => {
  const accessToken = window.localStorage.getItem('accessToken');

  config.headers.Authorization = accessToken ? `${accessToken}` : '';
  config.headers.EngineType = engineType;

  return config;
});

// HOTEL
export const axiosInstance_searchEngine = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? endPoints.prodHotelApiEndPoint
      : endPoints.devHotelApiEndPoint
});

axiosInstance_searchEngine.interceptors.response.use(
  (response) => {
    if (response.headers.client) {
      const token = response.headers.Token;
      setSession(token);
    }

    return response;
  },
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

axiosInstance_searchEngine.interceptors.request.use((config) => {
  const accessToken = window.localStorage.getItem('accessToken');

  config.headers.Authorization = accessToken ? `${accessToken}` : '';
  config.headers.EngineType = engineType;

  return config;
});

// FLIGHT
export const axiosInstance_searchFareEngine = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? endPoints.prodFlightApiEndPoint
      : endPoints.devFlightApiEndPoint
});

axiosInstance_searchFareEngine.interceptors.response.use(
  (response) => {
    if (response.headers.client) {
      const token = response.headers.Token;
      setSession(token);
    }

    return response;
  },
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

axiosInstance_searchFareEngine.interceptors.request.use((config) => {
  const accessToken = window.localStorage.getItem('accessToken');

  config.headers.Authorization = accessToken ? `${accessToken}` : '';
  config.headers.EngineType = engineType;

  return config;
});

// Reports
export const axiosRptInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production' ? endPoints.prodRptEndPoint : endPoints.devRptEndPoint,
  headers: {
    post: {
      'Content-Type': 'application/json'
    }
  }
});

axiosRptInstance.interceptors.response.use(
  (response) => {
    if (response.headers.client) {
      const token = response.headers.Token;
      setSession(token);
    }

    return response;
  },
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

axiosRptInstance.interceptors.request.use((config) => {
  const accessToken = window.localStorage.getItem('accessToken');

  config.headers.Authorization = accessToken ? `${accessToken}` : '';
  config.headers.EngineType = engineType;

  return config;
});

// Activity
export const axiosInstance_searchActivityEngine = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? endPoints.prodActivityApiEndPoint
      : endPoints.devActivityApiEndPoint
});

axiosInstance_searchActivityEngine.interceptors.response.use(
  (response) => {
    if (response.headers.client) {
      const token = response.headers.Token;
      setSession(token);
    }

    return response;
  },
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

axiosInstance_searchActivityEngine.interceptors.request.use((config) => {
  const accessToken = window.localStorage.getItem('accessToken');

  config.headers.Authorization = accessToken ? `${accessToken}` : '';
  config.headers.EngineType = engineType;

  return config;
});

export default axiosInstance;

// UploadImages
export const axiosImgInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production' ? endPoints.prodBOEndPoint : endPoints.devBOEndPoint,
  headers: {
    post: {
      'Content-Type': 'multipart/form-data'
    }
  }
});

axiosImgInstance.interceptors.response.use(
  (response) => {
    if (response.headers.client) {
      const token = response.headers.Token;
      setSession(token);
    }

    return response;
  },
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

axiosImgInstance.interceptors.request.use((config) => {
  const accessToken = window.localStorage.getItem('accessToken');

  config.headers.Authorization = accessToken ? `${accessToken}` : '';
  config.headers.EngineType = engineType;

  return config;
});

export const axiosBoson = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? endPoints.prodBosonEndPoint
      : endPoints.prodBosonEndPoint,
  headers: {
    post: {
      'Content-Type': 'application/json'
    }
  }
});

axiosBoson.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);
