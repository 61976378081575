// import { capitalCase } from 'change-case';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Container, Typography, Grid, Divider } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
// import useAuth from '../../hooks/useAuth';
// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';
import { endPoints } from '../../config';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const getCompanyDomain = (hostName: string) => {
  if (hostName === 'localhost') {
    return 'ScaleHorizon';
  }

  const subDomain = hostName.split('.');
  if (subDomain[0] === 'backoffice') {
    return 'ScaleHorizon';
  } else if (subDomain[0] === 'oceandusk') {
    return 'OceanDusk';
  } else if (subDomain[0] === 'appwell') {
    return 'Appwell';
  }
};

// ----------------------------------------------------------------------

export default function Login() {
  // const { method } = useAuth();
  const endPoint =
    process.env.NODE_ENV === 'production' ? endPoints.prodCDNEndPoint : endPoints.prodCDNEndPoint;

  const companyName = getCompanyDomain(window.location.hostname);
  let b2bBackGroudImg = `${endPoint}/assets/images/home/od_banner.jpg`;
  let companyLogo = `${endPoint}/assets/images/home/CompanyLogo.svg`;
  let bgColor = '#142c64';
  if (companyName === 'OceanDusk') {
    b2bBackGroudImg = `${endPoint}/assets/images/home/ODOS_Header.png`;
    companyLogo = `${endPoint}/assets/images/home/od_brown_logo.jpeg`;
    bgColor = '#343331';
  } else if (companyName === 'Appwell') {
    companyLogo = `${endPoint}/assets/images/home/appwell.jpeg`;
    bgColor = '#03CFCE';
  }

  return (
    <RootStyle title="Login">
      <Box
        sx={{
          position: 'relative',
          backgroundImage: `url(${b2bBackGroudImg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          objectFit: 'cover',
          height: '100vh',
          minHeight: '100vh',
          width: '100%'
        }}
      >
        <Grid container sx={{ pt: 20, pl: 5, pr: 5 }}>
          <Grid item xs={12} md={1}></Grid>
          <Grid item xs={12} md={2}></Grid>
          <Grid item xs={12} md={1}></Grid>
          <Grid item xs={12} md={2}></Grid>
          <Grid item xs={12} md={4} />

          <Grid item xs={12} md={4} />
          <Grid item xs={12} md={4}>
            <Card sx={{ p: 2 }}>
              <Stack
                direction="column"
                alignItems="center"
                sx={{ mb: 2, backgroundColor: bgColor }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 130,
                    width: 250,
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  src={companyLogo}
                />
              </Stack>
              <LoginForm />
            </Card>
          </Grid>
          <Grid item xs={12} md={4} />
        </Grid>
      </Box>
    </RootStyle>
  );
}
