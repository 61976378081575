import { format, formatDistanceToNow, differenceInDays, differenceInHours } from 'date-fns';

// ----------------------------------------------------------------------
export function fDateyyyyMMdd(date: string | number | Date) {
  return format(new Date(date), 'yyyy-MM-dd');
}
export function fDateddMMyyyy(date: string | number | Date) {
  return format(new Date(date), 'dd/MM/yyyy');
}

export function fDate(date: string | number | Date) {
  return format(new Date(date), 'dd MMM yyyy');
}

export function fDatedddddMMM(date: string | number | Date) {
  return format(new Date(date), 'EEE, dd MMM');
}

export function fDateTime(date: string | number | Date) {
  if (typeof date === 'string' && date.slice(0, 4) === '0001') {
    return '';
  }
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date: string | number | Date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fDateDB(date: Date) {
  return date && date.toString() !== 'Invalid Date' ? format(new Date(date), 'yyyyMMdd') : '';
}

export function fTime(date: string | number | Date) {
  return format(new Date(date), 'HH:mm');
}

export function convertToDate(datestr: string) {
  if (datestr === '') {
    return new Date();
  }

  const year = parseInt(datestr.substring(0, 4), 10);
  const month = parseInt(datestr.substring(4, 6), 10);
  const day = parseInt(datestr.substring(6, 8), 10);

  const date = new Date(year, month - 1, day);

  return date;
}

export function convertDDMMYYToDate(sDay: string, sMonth: string, sYear: string) {
  const year = parseInt(sYear, 10);
  const month = parseInt(sMonth, 10);
  const day = parseInt(sDay, 10);

  const date = new Date(year, month, day);

  return date;
}

export function convertToDateDash(datestr: string) {
  const arrDate = datestr.split('-');
  const year = parseInt(arrDate[0], 10);
  const month = parseInt(arrDate[1], 10);
  const day = parseInt(arrDate[2], 10);

  const date = new Date(year, month - 1, day);

  return date;
}

export function fToNow(date: string | number | Date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function dbDateToUIFormat(date: string | number | Date) {
  if (typeof date === 'string' && date.slice(0, 4) === '0001') {
    return '';
  }
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function dbDateToUIFormatWT(date: string | number | Date) {
  if (typeof date === 'string' && date.slice(0, 4) === '0001') {
    return '';
  }
  return format(new Date(date), 'dd MMM yyyy');
}

export function uiDateToDBFormat(date: Date | null) {
  return date && date !== null && date.toString() !== 'Invalid Date'
    ? format(new Date(date), "yyyy-MM-dd'T'00:00:00.000+00:00")
    : '0001-01-01T00:00:00.000+00:00';
}

export function dateDiffDays(date1: Date, date2: Date) {
  return differenceInDays(date1, date2);
}

export function dateDiffHours(date1: Date, date2: Date) {
  return differenceInHours(date1, date2);
}

export function UIDateFormat(yyyymmdd: string) {
  if (yyyymmdd === '') {
    return yyyymmdd;
  }

  const year = parseInt(yyyymmdd.substring(0, 4), 10);
  const month = parseInt(yyyymmdd.substring(4, 6), 10);
  const day = parseInt(yyyymmdd.substring(6, 8), 10);

  const date = new Date(year, month - 1, day);

  return format(date, 'dd MMM yyyy');
}
