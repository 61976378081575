// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 26,
  height: 26
};

const menuConfig = [
  {
    title: 'ABOUT US',
    icon: <Iconify icon="eva:home-fill" {...ICON_SIZE} />,
    path: '#aboutus'
  },
  {
    title: 'SERVICES',
    icon: <Iconify icon="eva:home-fill" {...ICON_SIZE} />,
    path: '#destinations'
  },
  {
    title: 'CONTACT US',
    icon: <Iconify icon="eva:home-fill" {...ICON_SIZE} />,
    path: '#contactus'
  },
  {
    title: 'TERMS & CONDITIONS',
    icon: <Iconify icon="eva:home-fill" {...ICON_SIZE} />,
    path: '#terms'
  }
];

export default menuConfig;
