import * as Yup from 'yup';
import { EntityInterface } from '../EntityInterface';
import { EntityFormInterface } from '../EntityFormInterface';
import { PATH_DASHBOARD } from '../../routes/paths';
import { yuphelper } from '../../utils/yuphelper';

const EntityYupSchema = {
  Code: yuphelper.STR_REQ,
  Name: yuphelper.STR_REQ,
  CategoryType: yuphelper.STR_REQ
};

const getFormikInitialValuesHandler = (entity: any) =>
  entity
    ? {
        Code: entity.Code,
        Name: entity.Name,
        CategoryType: entity.CategoryType
      }
    : {
        Code: '',
        Name: '',
        CategoryType: ''
      };

export const ActivityCategoryForm: EntityFormInterface = {
  title: 'Activity Category',
  moduleName: 'Legend',
  entityName: 'ActivityCategory',
  entityType: 'SELF',
  entityYupSchema: EntityYupSchema,
  getFormikInitialValues: getFormikInitialValuesHandler,
  elementGroups: [
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Code',
          field: 'Code',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        },
        {
          label: 'Name',
          field: 'Name',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'CategoryType',
          field: 'CategoryType',
          type: 'string',
          control: 'select',
          values: [
            {
              label: 'Activity',
              value: 'A'
            },
            {
              label: 'Wellness',
              value: 'W'
            },
            {
              label: 'LocationType',
              value: 'L'
            },
            {
              label: 'SuitedFor',
              value: 'S'
            }
          ],
          width: 100
        }
      ]
    }
  ]
};

export const ActivityCategory: EntityInterface = {
  title: 'Activity Category',
  moduleName: 'Legend',
  entityName: 'ActivityCategory',
  entityType: 'SELF',
  entityForm: ActivityCategoryForm,
  sortField: 'Name',
  sortOption: 'asc',
  filterField: 'Name',
  rowsPerPage: 5,
  filterOption: 'contains',
  isDense: false,
  totalRecords: 10,
  tables: [
    {
      field: '_id',
      label: '_id',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: false
    },
    {
      field: 'Code',
      label: 'Code',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'Name',
      label: 'Name',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'CategoryType',
      label: 'CategoryType',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'CreatedBy',
      label: 'CreatedBy',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'CreatedDate',
      label: 'CreatedDate',
      alignRight: false,
      type: 'date',
      format: 'DD MMM YYYY HH:SS',
      width: 120,
      filter: false
    },
    {
      field: 'ModifiedBy',
      label: 'ModifiedBy',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'ModifiedDate',
      label: 'ModifiedDate',
      alignRight: false,
      type: 'date',
      format: 'DD MMM YYYY HH:SS',
      width: 120,
      filter: false
    }
  ],
  headerBreadCrumbs: [
    { name: 'Dashboard', href: PATH_DASHBOARD.root },
    { name: 'Legends', href: PATH_DASHBOARD.legends.root },
    { name: 'ActivityCategory', href: PATH_DASHBOARD.legends.activitycategory },
    { name: 'List' }
  ]
};

export default ActivityCategory;
