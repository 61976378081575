import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Tab(theme: Theme) {
  return {
    MuiTab: {
      styleOverrides: {
        root: {
          '@media (min-width: 600px)': {
            minWidth: 100
          }
        }
      }
    }
  };
}
