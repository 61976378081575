import { forwardRef } from 'react';
import { Button, ButtonProps } from '@mui/material';
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------
interface MButtonProps extends ButtonProps {
  Module: string;
  CrudType: string;
}

const MButton = forwardRef<HTMLButtonElement, MButtonProps>(
  ({ Module = '', CrudType = '', children, sx, ...other }, ref) => {
    const { userRights } = useAuth();

    const moduleRights =
      userRights && userRights.Rights.find((element: any) => element.ModuleCode === Module);

    console.log('userRights', userRights, Module);
    let isAuthorized: boolean = false;
    if (moduleRights) {
      if (moduleRights.Full) {
        isAuthorized = true;
      } else if (CrudType === 'Read' && moduleRights.Read) {
        isAuthorized = true;
      } else if ((CrudType === 'Create' || CrudType === 'ADD') && moduleRights.Create) {
        isAuthorized = true;
      } else if (CrudType === 'Delete' && moduleRights.Delete) {
        isAuthorized = true;
      } else if ((CrudType === 'Update' || CrudType === 'UPDATE') && moduleRights.Update) {
        isAuthorized = true;
      }
    }

    return (
      <Button disabled={!isAuthorized} ref={ref} sx={sx} {...other}>
        {children}
      </Button>
    );
  }
);

export default MButton;
