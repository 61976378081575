// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 26,
  height: 26
};

const menuConfig = [
  {
    title: 'English',
    icon: <Iconify icon="eva:home-fill" {...ICON_SIZE} />,
    path: '/'
  },
  {
    title: 'Support',
    icon: <Iconify icon="eva:home-fill" {...ICON_SIZE} />,
    path: '/support'
  },
  {
    title: 'My Trips',
    icon: <Iconify icon="eva:home-fill" {...ICON_SIZE} />,
    path: '/mytrips'
  },
  {
    title: 'Sign in',
    icon: <Iconify icon="eva:home-fill" {...ICON_SIZE} />,
    path: '/signin'
  }
];

export default menuConfig;
