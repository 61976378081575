import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import commonReducer from './slices/common';
import entityReducer from './slices/entity';
import hotelReducer from './slices/hotel';
import activityReducer from './slices/activity';
import fareReducer from './slices/fare';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const commonPersistConfig = {
  key: 'common',
  storage
};

const rootReducer = combineReducers({
  fare: fareReducer,
  hotel: hotelReducer,
  activity: activityReducer,
  entity: entityReducer,
  common: persistReducer(commonPersistConfig, commonReducer)
});

export { rootPersistConfig, rootReducer };
