// material
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
import useAuth from '../hooks/useAuth';
import { PATH_DASHBOARD, PATH_DASHBOARD_B2C } from '../routes/paths';
import { engineType } from '../config';
// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { companyLogo, companyName } = useAuth();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;
  // const LOGO = '</>';
  // const COMPANY_NAME = 'ScaleHorizon';

  const navigateRoot =
    engineType === 'B2C'
      ? PATH_DASHBOARD_B2C.booking.searchengine
      : PATH_DASHBOARD.booking.searchengine;

  return (
    <Box sx={{ ...sx }}>
      {companyLogo && (
        <>
          <Box
            component="img"
            sx={{
              height: 110,
              width: 240,
              alignItems: 'center',
              justifyContent: 'center'
            }}
            src={companyLogo}
            onClick={() => navigate(navigateRoot)}
          />
        </>
      )}
    </Box>
  );
}
