import * as Yup from 'yup';
import { EntityInterface } from '../EntityInterface';
import { EntityFormInterface } from '../EntityFormInterface';
import { PATH_DASHBOARD } from '../../routes/paths';
import { yuphelper } from '../../utils/yuphelper';

export type FormikInitialValues = {};

const EntityYupSchema = {
  Code: yuphelper.CODE3_REQ,
  Name: yuphelper.STR_REQ,
  FacilityGroup: yuphelper.IN_CODE2_REQ
};

const getFormikInitialValuesHandler = (entity: any) =>
  entity
    ? {
        Code: entity.Code,
        Name: entity.Name,
        Facility: entity.Name,
        FacilityGroup: entity.FacilityGroup
      }
    : {
        Code: '',
        Name: '',
        Facility: '',
        FacilityGroup: { Code: '', Name: '' }
      };

export const FacilityForm: EntityFormInterface = {
  title: '`Facility',
  moduleName: 'Legend',
  entityName: 'Facility',
  entityType: 'SELF',
  entityYupSchema: EntityYupSchema,
  getFormikInitialValues: getFormikInitialValuesHandler,
  elementGroups: [
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Code',
          field: 'Code',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        },
        {
          label: 'Name',
          field: 'Name',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Facility Group',
          field: 'FacilityGroup',
          type: 'string',
          control: 'selectserverfill',
          width: 100,
          values: []
        }
      ]
    }
  ]
};

export const Facility: EntityInterface = {
  title: 'Facility',
  moduleName: 'Legend',
  entityName: 'Facility',
  entityType: 'SELF',
  entityForm: FacilityForm,
  sortField: 'Name',
  sortOption: 'asc',
  filterField: 'Name',
  rowsPerPage: 5,
  filterOption: 'contains',
  isDense: false,
  totalRecords: 10,
  tables: [
    {
      field: '_id',
      label: '_id',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: false
    },
    {
      field: 'Code',
      label: 'Code',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'Name',
      label: 'Name',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'FacilityGroup',
      label: 'FacilityGroup',
      alignRight: false,
      type: 'KeyValue',
      width: 120,
      filter: true
    },
    {
      field: 'CreatedBy',
      label: 'CreatedBy',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'CreatedDate',
      label: 'CreatedDate',
      alignRight: false,
      type: 'date',
      format: 'DD MMM YYYY HH:SS',
      width: 120,
      filter: false
    },
    {
      field: 'ModifiedBy',
      label: 'ModifiedBy',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'ModifiedDate',
      label: 'ModifiedDate',
      alignRight: false,
      type: 'date',
      format: 'DD MMM YYYY HH:SS',
      width: 120,
      filter: false
    }
  ],
  headerBreadCrumbs: [
    { name: 'Dashboard', href: PATH_DASHBOARD.root },
    { name: 'Legends', href: PATH_DASHBOARD.legends.root },
    { name: 'Facility', href: PATH_DASHBOARD.legends.facility },
    { name: 'List' }
  ]
};

export default Facility;
