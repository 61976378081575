import * as Yup from 'yup';
import { EntityInterface } from '../EntityInterface';
import { EntityFormInterface } from '../EntityFormInterface';
import { PATH_DASHBOARD } from '../../routes/paths';
import { yuphelper } from '../../utils/yuphelper';

const EntityYupSchema = {
  Code: yuphelper.CODE3_REQ,
  Name: yuphelper.STR_REQ,
  AccomodationCommission: yuphelper.MONEY_NON_REQ,
  FlightsCommission: yuphelper.MONEY_NON_REQ,
  ActivityCommission: yuphelper.MONEY_NON_REQ,
  CreditLimit: yuphelper.MONEY_NON_REQ,
  CreditDays: yuphelper.INT_NON_REQ
};

const getFormikInitialValuesHandler = (entity: any) =>
  entity
    ? {
        Code: entity.Code,
        Name: entity.Name,
        Market: entity.Market,
        ClientGroup: entity.ClientGroup,
        Currency: entity.Currency,
        SameAsBuy: entity.SameAsBuy,
        IsCreditAgent: entity.IsCreditAgent,
        CreditDays: entity.CreditDays,
        CreditLimit: entity.CreditLimit,
        Commission: entity.Commission,
        AccomodationCommission: entity.AccomodationCommission,
        FlightsCommission: entity.FlightsCommission,
        ActivityCommission: entity.ActivityCommission,
        VatOnCommission: entity.VatOnCommission
      }
    : {
        Code: '',
        Name: '',
        Market: { Code: '', Name: '' },
        ClientGroup: { Code: '', Name: '' },
        Currency: { Code: '', Name: '' },
        SameAsBuy: false,
        IsCreditAgent: false,
        CreditDays: 0,
        CreditLimit: 0,
        Commission: false,
        AccomodationCommission: 0,
        FlightsCommission: 0,
        ActivityCommission: 0,
        VatOnCommission: false
      };

export const ClientForm: EntityFormInterface = {
  title: 'Client',
  moduleName: 'Client',
  entityName: 'Client',
  entityType: 'SELF',
  entityYupSchema: EntityYupSchema,
  getFormikInitialValues: getFormikInitialValuesHandler,
  elementGroups: [
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Code',
          field: 'Code',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        },
        {
          label: 'Name',
          field: 'Name',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Market',
          field: 'Market',
          type: 'string',
          control: 'selectserverfill',
          width: 100,
          values: []
        },
        {
          label: 'ClientGroup',
          field: 'ClientGroup',
          type: 'string',
          control: 'selectserverfill',
          width: 100,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Currency',
          field: 'Currency',
          type: 'string',
          control: 'selectserverfill',
          width: 100,
          values: []
        },
        {
          label: 'Currency Same As Buy',
          field: 'SameAsBuy',
          type: 'boolean',
          control: 'switch',
          width: 100,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Commission',
          field: 'Commission',
          type: 'boolean',
          control: 'switch',
          width: 100,
          values: []
        },
        {
          label: 'Vat On Commission',
          field: 'VatOnCommission',
          type: 'boolean',
          control: 'switch',
          width: 100,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'AccomodationCommission',
          field: 'AccomodationCommission',
          type: 'integer',
          control: 'textbox',
          width: 100,
          values: []
        },
        {
          label: 'FlightsCommission',
          field: 'FlightsCommission',
          type: 'integer',
          control: 'textbox',
          width: 100,
          values: []
        },
        {
          label: 'ActivityCommission',
          field: 'ActivityCommission',
          type: 'integer',
          control: 'textbox',
          width: 100,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'IsCreditAgent',
          field: 'IsCreditAgent',
          type: 'boolean',
          control: 'switch',
          width: 100,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'CreditLimit',
          field: 'CreditLimit',
          type: 'integer',
          control: 'textbox',
          width: 100,
          values: []
        },
        {
          label: 'CreditDays',
          field: 'CreditDays',
          type: 'float',
          control: 'textbox',
          width: 100,
          values: []
        }
      ]
    }
  ]
};

export const Client: EntityInterface = {
  title: 'Client',
  moduleName: 'Client',
  entityName: 'Client',
  entityType: 'SELF',
  entityForm: ClientForm,
  sortField: 'Name',
  sortOption: 'asc',
  filterField: 'Name',
  rowsPerPage: 5,
  filterOption: 'contains',
  wizardUrl: 'clientdetails',
  isDense: false,
  totalRecords: 10,
  tables: [
    {
      field: '_id',
      label: '_id',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: false
    },
    {
      field: 'Code',
      label: 'Code',
      alignRight: false,
      type: 'string',
      width: 100,
      filter: true
    },
    {
      field: 'Name',
      label: 'Name',
      alignRight: false,
      type: 'string',
      width: 100,
      filter: true
    },
    {
      field: '',
      label: 'Consultants',
      alignRight: false,
      type: 'Navigate',
      width: 120,
      filter: false,
      Url: 'Test'
    },
    {
      field: 'Market',
      label: 'Market',
      alignRight: false,
      type: 'KeyValue',
      width: 80,
      filter: true
    },
    {
      field: 'ClientType',
      label: 'ClientType',
      alignRight: false,
      type: 'string',
      width: 50,
      filter: false
    },
    {
      field: 'ClientGroup',
      label: 'ClientGroup',
      alignRight: false,
      type: 'KeyValue',
      width: 120,
      filter: true
    },
    {
      field: 'Currency',
      label: 'Currency',
      alignRight: false,
      type: 'KeyValue',
      width: 120,
      filter: true
    },
    {
      field: '',
      label: 'Credit',
      alignRight: false,
      type: 'Link',
      width: 120,
      filter: false,
      Url: 'Test'
    },
    {
      field: 'CreatedBy',
      label: 'CreatedBy',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'CreatedDate',
      label: 'CreatedDate',
      alignRight: false,
      type: 'date',
      format: 'DD MMM YYYY HH:SS',
      width: 150,
      filter: false
    },
    {
      field: 'ModifiedBy',
      label: 'ModifiedBy',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'ModifiedDate',
      label: 'ModifiedDate',
      alignRight: false,
      type: 'date',
      format: 'DD MMM YYYY HH:SS',
      width: 150,
      filter: false
    }
  ],
  headerBreadCrumbs: [
    { name: 'Dashboard', href: PATH_DASHBOARD.root },
    { name: 'Wizards', href: PATH_DASHBOARD.wizards.root },
    { name: 'Client', href: PATH_DASHBOARD.wizards.client },
    { name: 'List' }
  ]
};

export default Client;
