import * as Yup from 'yup';
import { EntityInterface } from '../EntityInterface';
import { EntityFormInterface } from '../EntityFormInterface';
import { PATH_DASHBOARD } from '../../routes/paths';
import { yuphelper } from '../../utils/yuphelper';

const EntityYupSchema = {
  Code: yuphelper.CODE3_REQ,
  Name: yuphelper.STR_REQ
};

const getFormikInitialValuesHandler = (entity: any) =>
  entity
    ? {
        Code: entity.Code,
        Name: entity.Name
      }
    : {
        Code: '',
        Name: ''
      };

export const UserGroupForm: EntityFormInterface = {
  title: 'User Group',
  moduleName: 'UGP',
  entityName: 'UserGroup',
  entityType: 'SELF',
  entityYupSchema: EntityYupSchema,
  getFormikInitialValues: getFormikInitialValuesHandler,
  elementGroups: [
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Code',
          field: 'Code',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        },
        {
          label: 'Name',
          field: 'Name',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        }
      ]
    }
  ]
};

export const UserGroup: EntityInterface = {
  title: 'User Group',
  moduleName: 'UGP',
  entityName: 'UserGroup',
  entityType: 'SELF',
  entityForm: UserGroupForm,
  sortField: 'Name',
  sortOption: 'asc',
  filterField: 'Name',
  rowsPerPage: 5,
  filterOption: 'contains',
  isDense: false,
  totalRecords: 20,
  tables: [
    {
      field: '_id',
      label: '_id',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: false
    },
    {
      field: 'Code',
      label: 'Code',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'Name',
      label: 'Name',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: '',
      label: 'Permissions',
      alignRight: false,
      type: 'Link',
      width: 120,
      filter: true,
      Url: 'Test'
    },
    {
      field: 'CreatedBy',
      label: 'CreatedBy',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'CreatedDate',
      label: 'CreatedDate',
      alignRight: false,
      type: 'date',
      format: 'DD MMM YYYY HH:SS',
      width: 120,
      filter: false
    },
    {
      field: 'ModifiedBy',
      label: 'ModifiedBy',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'ModifiedDate',
      label: 'ModifiedDate',
      alignRight: false,
      type: 'date',
      format: 'DD MMM YYYY HH:SS',
      width: 120,
      filter: false
    }
  ],
  headerBreadCrumbs: [
    { name: 'Dashboard', href: PATH_DASHBOARD.root },
    { name: 'Wizards', href: PATH_DASHBOARD.wizards.root },
    { name: 'UserGroup', href: PATH_DASHBOARD.wizards.usergroup },
    { name: 'List' }
  ]
};

export default UserGroup;
