import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { EntityActionStatus } from '../../@types/entity';

// ----------------------------------------------------------------------

type EntityState = {
  isLoading: boolean;
  error: boolean;
  entityList: any[];
  entity: null | any;
  insertStatus: null | EntityActionStatus;
};

const initialState: EntityState = {
  isLoading: false,
  error: false,
  entityList: [],
  entity: null,
  insertStatus: null
};

const slice = createSlice({
  name: 'entity',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.entity = [];
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // By Id ENTITIES
    getEntityByIdSuccess(state, action) {
      state.isLoading = false;
      state.entity = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getEntityById(entityName: string, entityId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/GetEntityById/${entityName}/${entityId}`);

      dispatch(
        slice.actions.getEntityByIdSuccess(response.data.response.Entities[0].EntityRows[0])
      );
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
