// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_DASHBOARD_B2C = '';

// ----------------------------------------------------------------------
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD_B2C = {
  booking: {
    searchengine: path(ROOTS_DASHBOARD_B2C, '/'),
    hotelsearch: path(ROOTS_DASHBOARD_B2C, '/hotelsearch'),
    hotelinformation: path(ROOTS_DASHBOARD_B2C, '/hotelinformation'),
    hotelcheckout: path(ROOTS_DASHBOARD_B2C, '/hotelcheckout'),
    flightcheckout: path(ROOTS_DASHBOARD_B2C, '/flightcheckout'),
    flightsearch: path(ROOTS_DASHBOARD_B2C, '/flightsearch'),
    activitysearch: path(ROOTS_DASHBOARD_B2C, '/activitysearch'),
    activityinformation: path(ROOTS_DASHBOARD_B2C, '/activityinformation'),
    activitycheckout: path(ROOTS_DASHBOARD_B2C, '/activitycheckout'),
    manageBooking: path(ROOTS_DASHBOARD_B2C, '/bookingdetails'),
    mytrip: path(ROOTS_DASHBOARD_B2C, '/mytrips'),
    hotelpackagesearch: path(ROOTS_DASHBOARD_B2C, '/hotelpackagesearch'),
    hotelpackageinformation: path(ROOTS_DASHBOARD_B2C, '/hotelpackageinformation')
  }
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  analytics: {
    root: path(ROOTS_DASHBOARD, '/analytics')
  },
  menu: {
    root: path(ROOTS_DASHBOARD, '/menu')
  },
  wizards: {
    root: path(ROOTS_DASHBOARD, '/wizards'),
    hotelallocation: path(ROOTS_DASHBOARD, '/wizards/hotelallocation'),
    client: path(ROOTS_DASHBOARD, '/wizards/client'),
    clientemployee: path(ROOTS_DASHBOARD, '/wizards/clientemployee'),
    employee: path(ROOTS_DASHBOARD, '/wizards/employee'),
    flightmarkup: path(ROOTS_DASHBOARD, '/wizards/flightmarkup'),
    hotelthirdpartymarkup: path(ROOTS_DASHBOARD, '/wizards/hotelthirdpartymarkup'),
    usergroup: path(ROOTS_DASHBOARD, '/wizards/usergroup'),
    clienttariff: path(ROOTS_DASHBOARD, '/wizards/clienttariff'),
    activity: path(ROOTS_DASHBOARD, '/wizards/activity'),
    hotel: path(ROOTS_DASHBOARD, '/wizards/hotel'),
    activitydetails: path(ROOTS_DASHBOARD, '/wizards/activitydetails'),
    hoteldetails: path(ROOTS_DASHBOARD, '/wizards/hoteldetails')
  },
  masters: {
    root: path(ROOTS_DASHBOARD, '/masters'),
    clientgroup: path(ROOTS_DASHBOARD, '/masters/clientgroup'),
    currencyexchange: path(ROOTS_DASHBOARD, '/masters/currencyexchange'),
    department: path(ROOTS_DASHBOARD, '/masters/department'),
    designation: path(ROOTS_DASHBOARD, '/masters/designation'),
    discounttype: path(ROOTS_DASHBOARD, '/masters/discounttype'),
    market: path(ROOTS_DASHBOARD, '/masters/market'),
    product: path(ROOTS_DASHBOARD, '/masters/product'),
    supplement: path(ROOTS_DASHBOARD, '/masters/supplement'),
    supplier: path(ROOTS_DASHBOARD, '/masters/supplier'),
    tariff: path(ROOTS_DASHBOARD, '/masters/tariff'),
    vat: path(ROOTS_DASHBOARD, '/masters/vat')
  },
  legends: {
    root: path(ROOTS_DASHBOARD, '/legends'),
    airport: path(ROOTS_DASHBOARD, '/legends/airport'),
    currency: path(ROOTS_DASHBOARD, '/legends/currency'),
    language: path(ROOTS_DASHBOARD, '/legends/language'),
    city: path(ROOTS_DASHBOARD, '/legends/city'),
    country: path(ROOTS_DASHBOARD, '/legends/country'),
    module: path(ROOTS_DASHBOARD, '/legends/module'),
    market: path(ROOTS_DASHBOARD, '/legends/market'),
    destination: path(ROOTS_DASHBOARD, '/legends/Destination'),
    category: path(ROOTS_DASHBOARD, '/legends/Category'),
    accommodationtype: path(ROOTS_DASHBOARD, '/legends/accommodationtype'),
    facility: path(ROOTS_DASHBOARD, '/legends/facility'),
    activitycategory: path(ROOTS_DASHBOARD, '/legends/activitycategory'),
    activitysubcategory: path(ROOTS_DASHBOARD, '/legends/activitysubcategory')
  },
  booking: {
    root: path(ROOTS_DASHBOARD, '/booking'),
    searchengine: path(ROOTS_DASHBOARD, '/booking/searchengine'),
    hotelsearch: path(ROOTS_DASHBOARD, '/booking/hotelsearch'),
    hotelinformation: path(ROOTS_DASHBOARD, '/booking/hotelinformation'),
    hotelcheckout: path(ROOTS_DASHBOARD, '/booking/hotelcheckout'),
    flightcheckout: path(ROOTS_DASHBOARD, '/booking/flightcheckout'),
    flightsearch: path(ROOTS_DASHBOARD, '/booking/flightsearch'),
    activitysearch: path(ROOTS_DASHBOARD, '/booking/activitysearch'),
    activityinformation: path(ROOTS_DASHBOARD, '/booking/activityinformation'),
    activitycheckout: path(ROOTS_DASHBOARD, '/booking/activitycheckout'),
    importpnr: path(ROOTS_DASHBOARD, '/booking/importpnr'),
    manualbooking: path(ROOTS_DASHBOARD, '/booking/manualbooking'),
    yearlysearch: path(ROOTS_DASHBOARD, '/booking/yearlysearch'),
    debugsearch: path(ROOTS_DASHBOARD, '/booking/debugsearch'),
    hotelpackagesearch: path(ROOTS_DASHBOARD, '/booking/hotelpackagesearch'),
    hotelpackageinformation: path(ROOTS_DASHBOARD, '/booking/hotelpackageinformation')
  },
  managebookings: {
    root: path(ROOTS_DASHBOARD, '/manage'),
    manageBooking: path(ROOTS_DASHBOARD, '/manage/bookings')
  },
  find: {
    root: path(ROOTS_DASHBOARD, '/find'),
    find: path(ROOTS_DASHBOARD, '/find/find')
  },
  accounts: {
    root: path(ROOTS_DASHBOARD, '/accounts'),
    payments: path(ROOTS_DASHBOARD, '/accounts/payments'),
    invoices: path(ROOTS_DASHBOARD, '/accounts/invoices'),
    vcntransactions: path(ROOTS_DASHBOARD, '/accounts/vcntransactions'),
    carddetails: path(ROOTS_DASHBOARD, '/accounts/carddetails')
  },
  help: {
    root: path(ROOTS_DASHBOARD, '/help'),
    help: path(ROOTS_DASHBOARD, '/help/help')
  },
  settings: {
    root: path(ROOTS_DASHBOARD, '/settings'),
    companydetails: path(ROOTS_DASHBOARD, '/settings/companydetails'),
    settings: path(ROOTS_DASHBOARD, '/settings/settings')
  },
  reports: {
    root: path(ROOTS_DASHBOARD, '/reports'),
    audittrail: path(ROOTS_DASHBOARD, '/reports/audittrail'),
    clienttariffreport: path(ROOTS_DASHBOARD, '/reports/clienttariffreport'),
    tariffnotifications: path(ROOTS_DASHBOARD, '/reports/tariffnotifications'),
    logintrail: path(ROOTS_DASHBOARD, '/reports/logintrail'),
    warehouse: path(ROOTS_DASHBOARD, '/reports/warehouse'),
    activitypreparation: path(ROOTS_DASHBOARD, '/reports/activitypreparation'),
    hotelpreparation: path(ROOTS_DASHBOARD, '/reports/hotelpreparation')
  },
  documents: {
    root: path(ROOTS_DASHBOARD, '/documents'),
    documents: path(ROOTS_DASHBOARD, '/documents/documents')
  }
};
