import * as Yup from 'yup';
import { EntityInterface } from '../EntityInterface';
import { EntityFormInterface } from '../EntityFormInterface';
import { PATH_DASHBOARD } from '../../routes/paths';
import { yuphelper } from '../../utils/yuphelper';
import { uiDateToDBFormat } from '../../utils/formatTime';

const EntityYupSchema = {
  Code: yuphelper.CODE3_REQ,
  Name: yuphelper.STR_REQ,
  FromDate: yuphelper.STR_REQ,
  ToDate: yuphelper.STR_REQ,
  Interface: yuphelper.STR_REQ,
  Rate: yuphelper.MONEY_REQ
};

const getFormikInitialValuesHandler = (entity: any) =>
  entity
    ? {
        Code: entity.Code,
        Name: entity.Name,
        FromDate: entity.FromDate,
        ToDate: entity.ToDate,
        Interface: entity.Interface,
        TypeOfService: entity.TypeOfService,
        Rate: entity.Rate,
        TypeOfRate: entity.TypeOfRate
      }
    : {
        Code: '',
        Name: '',
        FromDate: uiDateToDBFormat(new Date()),
        ToDate: uiDateToDBFormat(new Date()),
        Interface: 'HotelBeds',
        TypeOfService: 'A',
        Rate: 0,
        TypeOfRate: 'P'
      };

export const HotelThirdpartyMarkupForm: EntityFormInterface = {
  title: 'Hotel Thirdparty Markup',
  moduleName: 'HTM',
  entityName: 'HotelThirdpartyMarkup',
  entityType: 'SELF',
  entityYupSchema: EntityYupSchema,
  getFormikInitialValues: getFormikInitialValuesHandler,
  elementGroups: [
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Code',
          field: 'Code',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        },
        {
          label: 'Name',
          field: 'Name',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'FromDate',
          field: 'FromDate',
          type: 'date',
          control: 'datepicker',
          width: 100,
          values: []
        },
        {
          label: 'ToDate',
          field: 'ToDate',
          type: 'date',
          control: 'datepicker',
          width: 100,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Interface',
          field: 'Interface',
          type: 'string',
          control: 'select',
          values: [
            {
              label: 'HOTEL_BEDS',
              value: 'HOTEL_BEDS'
            },
            {
              label: 'DERBY_SOFT',
              value: 'DERBY_SOFT'
            },
            {
              label: 'JUMEIRAH',
              value: 'JUMEIRAH'
            },
            {
              label: 'HYPERGUEST',
              value: 'HYPERGUEST'
            },
            {
              label: 'EXPEDIA',
              value: 'EXPEDIA'
            }
          ],
          width: 100
        },
        {
          label: 'TypeOfService',
          field: 'TypeOfService',
          type: 'string',
          control: 'select',
          values: [
            {
              label: 'Accomodation',
              value: 'A'
            },
            {
              label: 'Service',
              value: 'S'
            }
          ],
          width: 100
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Rate',
          field: 'Rate',
          type: 'number',
          control: 'textbox',
          width: 100,
          values: []
        },
        {
          label: 'TypeOfRate',
          field: 'TypeOfRate',
          type: 'string',
          control: 'select',
          values: [
            {
              label: 'Percentage',
              value: 'P'
            },
            {
              label: 'Amount',
              value: 'A'
            }
          ],
          width: 100
        }
      ]
    }
  ]
};

export const HotelThirdpartyMarkup: EntityInterface = {
  title: 'Hotel Thirdparty Markup',
  moduleName: 'HTM',
  entityName: 'HotelThirdpartyMarkup',
  entityType: 'SELF',
  entityForm: HotelThirdpartyMarkupForm,
  sortField: 'Name',
  sortOption: 'asc',
  filterField: 'Name',
  rowsPerPage: 5,
  filterOption: 'contains',
  wizardUrl: 'hotelthirdpartymarkup',
  isDense: false,
  totalRecords: 10,
  tables: [
    {
      field: '_id',
      label: '_id',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: false
    },
    {
      field: 'Code',
      label: 'Code',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'Name',
      label: 'Name',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'FromDate',
      label: 'FromDate',
      alignRight: false,
      type: 'date',
      width: 120,
      filter: true
    },
    {
      field: 'ToDate',
      label: 'ToDate',
      alignRight: false,
      type: 'date',
      width: 120,
      filter: true
    },
    {
      field: 'TypeOfService',
      label: 'TypeOfService',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'Interface',
      label: 'Interface',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'TypeOfRate',
      label: 'TypeOfRate',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'Rate',
      label: 'Rate',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'CreatedBy',
      label: 'CreatedBy',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'CreatedDate',
      label: 'CreatedDate',
      alignRight: false,
      type: 'date',
      format: 'DD MMM YYYY HH:SS',
      width: 120,
      filter: false
    },
    {
      field: 'ModifiedBy',
      label: 'ModifiedBy',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'ModifiedDate',
      label: 'ModifiedDate',
      alignRight: false,
      type: 'date',
      format: 'DD MMM YYYY HH:SS',
      width: 120,
      filter: false
    }
  ],
  headerBreadCrumbs: [
    { name: 'Dashboard', href: PATH_DASHBOARD.root },
    { name: 'Wizards', href: PATH_DASHBOARD.wizards.root },
    { name: 'HotelThirdpartyMarkup', href: PATH_DASHBOARD.wizards.hotelthirdpartymarkup },
    { name: 'List' }
  ]
};

export default HotelThirdpartyMarkup;
