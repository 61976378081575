import * as Yup from 'yup';
import { EntityInterface } from '../EntityInterface';
import { EntityFormInterface } from '../EntityFormInterface';
import { PATH_DASHBOARD } from '../../routes/paths';
import { yuphelper } from '../../utils/yuphelper';

const EntityYupSchema = {
  Code: yuphelper.CODE2_REQ,
  Name: yuphelper.STR_REQ
};

const getFormikInitialValuesHandler = (entity: any) =>
  entity
    ? {
        Code: entity.Code,
        Name: entity.Name,
        IsoCode: entity.IsoCode
      }
    : {
        Code: '',
        Name: '',
        IsoCode: ''
      };

export const CountryForm: EntityFormInterface = {
  title: 'Country',
  moduleName: 'Legend',
  entityName: 'Country',
  entityType: 'SELF',
  entityYupSchema: EntityYupSchema,
  getFormikInitialValues: getFormikInitialValuesHandler,
  elementGroups: [
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'Code',
          field: 'Code',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        },
        {
          label: 'Name',
          field: 'Name',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        }
      ]
    },
    {
      title: '2 x TextBox x AutoPreLoad',
      layout: '2',
      controls: [
        {
          label: 'IsoCode',
          field: 'IsoCode',
          type: 'string',
          control: 'textbox',
          width: 100,
          values: []
        }
      ]
    }
  ]
};

export const Country: EntityInterface = {
  title: 'Country',
  moduleName: 'Legend',
  entityName: 'Country',
  entityType: 'SELF',
  entityForm: CountryForm,
  sortField: 'Name',
  sortOption: 'asc',
  filterField: 'Name',
  rowsPerPage: 5,
  filterOption: 'contains',
  isDense: false,
  totalRecords: 10,
  tables: [
    {
      field: '_id',
      label: '_id',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: false
    },
    {
      field: 'Code',
      label: 'Code',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'Name',
      label: 'Name',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'PhoneCode',
      label: 'PhoneCode',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'IsoCode',
      label: 'IsoCode',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'CreatedBy',
      label: 'CreatedBy',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'CreatedDate',
      label: 'CreatedDate',
      alignRight: false,
      type: 'date',
      format: 'DD MMM YYYY HH:SS',
      width: 120,
      filter: false
    },
    {
      field: 'ModifiedBy',
      label: 'ModifiedBy',
      alignRight: false,
      type: 'string',
      width: 120,
      filter: true
    },
    {
      field: 'ModifiedDate',
      label: 'ModifiedDate',
      alignRight: false,
      type: 'date',
      format: 'DD MMM YYYY HH:SS',
      width: 120,
      filter: false
    }
  ],
  headerBreadCrumbs: [
    { name: 'Dashboard', href: PATH_DASHBOARD.root },
    { name: 'Legends', href: PATH_DASHBOARD.legends.root },
    { name: 'Country', href: PATH_DASHBOARD.legends.country },
    { name: 'List' }
  ]
};

export default Country;
