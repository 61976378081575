import { useState, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/authentication/Login';
import LoginB2B from '../pages/authentication/LoginB2B';
import { isValidToken } from '../utils/jwt';
import { engineType } from '../config';
// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node
};

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  const accessToken = window.localStorage.getItem('accessToken');

  if (!accessToken || !isValidToken(accessToken)) {
    return engineType !== 'B2B' ? <Login /> : <LoginB2B />;
  }

  /*
  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      if (accessToken && isValidToken(accessToken)) {
        setRequestedLocation(pathname);
      }
    }

    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }
  */

  return <>{children}</>;
}
