import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
// @types
import {
  ActionMap,
  AuthState,
  AuthUser,
  AuthUserRights,
  JWTContextType,
  LoginRS,
  Employee,
  Rights
} from '../@types/authentication';
import { engineType } from '../config';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER'
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
    b2bClient: any;
    userRights: AuthUserRights;
    uiType: string;
    flightProduct: any;
    hotelProduct: any;
    activityProduct: any;
    companyName: string;
    companyLogo: string;
  };
  [Types.Login]: {
    user: AuthUser;
    b2bClient: any;
    userRights: AuthUserRights;
    uiType: string;
    flightProduct: any;
    hotelProduct: any;
    activityProduct: any;
    companyName: string;
    companyLogo: string;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    isRegistrationSuccessful: boolean;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  b2bClient: null,
  userRights: null,
  uiType: '',
  flightProduct: { Code: '', Name: '' },
  hotelProduct: { Code: '', Name: '' },
  activityProduct: { Code: '', Name: '' },
  companyName: '',
  companyLogo: '',
  isRegistrationSuccessful: false
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isRegistrationSuccessful: false,
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        b2bClient: action.payload.b2bClient,
        userRights: action.payload.userRights,
        uiType: action.payload.uiType,
        flightProduct: action.payload.flightProduct,
        hotelProduct: action.payload.hotelProduct,
        activityProduct: action.payload.activityProduct,
        companyName: action.payload.companyName,
        companyLogo: action.payload.companyLogo
      };
    case 'LOGIN':
      return {
        ...state,
        isRegistrationSuccessful: false,
        isAuthenticated: true,
        user: action.payload.user,
        b2bClient: action.payload.b2bClient,
        userRights: action.payload.userRights,
        uiType: action.payload.uiType,
        flightProduct: action.payload.flightProduct,
        hotelProduct: action.payload.hotelProduct,
        activityProduct: action.payload.activityProduct,
        companyName: action.payload.companyName,
        companyLogo: action.payload.companyLogo
      };
    case 'LOGOUT':
      return {
        ...state,
        isRegistrationSuccessful: false,
        isAuthenticated: false,
        user: null,
        b2bClient: null,
        userRights: null,
        uiType: '',
        flightProduct: { Code: '', Name: '' },
        hotelProduct: { Code: '', Name: '' },
        activityProduct: { Code: '', Name: '' },
        companyName: '',
        companyLogo: ''
      };

    case 'REGISTER':
      return {
        ...state,
        isRegistrationSuccessful: action.payload.isRegistrationSuccessful,
        isAuthenticated: false,
        user: null,
        b2bClient: null,
        userRights: null,
        uiType: '',
        flightProduct: { Code: '', Name: '' },
        hotelProduct: { Code: '', Name: '' },
        activityProduct: { Code: '', Name: '' },
        companyName: '',
        companyLogo: ''
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if ((accessToken && isValidToken(accessToken)) || engineType === 'B2C') {
          const response = await axios.get('/GetUserDetailsById');
          const getUserDetailsRS: LoginRS = response.data.GetUserDetailsRS;
          const refreshedAccessToken: string = getUserDetailsRS.Token;
          const user: any = getUserDetailsRS.Employee
            ? getUserDetailsRS.Employee
            : getUserDetailsRS.ClientEmployee;

          // const uiType = getUserDetailsRS.Employee ? 'BO' : 'B2B';
          const userRights: Rights = getUserDetailsRS.Rights;

          setSession(refreshedAccessToken);

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user: getUserDetailsRS.Employee
                ? getUserDetailsRS.Employee
                : getUserDetailsRS.ClientEmployee,
              b2bClient: getUserDetailsRS.Employee ? null : getUserDetailsRS.Client,
              userRights: getUserDetailsRS.Rights,
              uiType: getUserDetailsRS.Employee ? 'BO' : 'B2B',
              flightProduct: getUserDetailsRS.FlightProduct,
              hotelProduct: getUserDetailsRS.HotelProduct,
              activityProduct: getUserDetailsRS.ActivityProduct,
              companyName: getUserDetailsRS.CompanyName,
              companyLogo: getUserDetailsRS.CompanyLogo
            }
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
              b2bClient: null,
              userRights: null,
              uiType: '',
              flightProduct: { Code: '', Name: '' },
              hotelProduct: { Code: '', Name: '' },
              activityProduct: { Code: '', Name: '' },
              companyName: '',
              companyLogo: ''
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
            b2bClient: null,
            userRights: null,
            uiType: '',
            flightProduct: { Code: '', Name: '' },
            hotelProduct: { Code: '', Name: '' },
            activityProduct: { Code: '', Name: '' },
            companyName: '',
            companyLogo: ''
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    console.log('LOGIN');
    const payload = {
      UserId: email,
      Password: password,
      CompanyId: 'ScaleHorizon'
    };

    const options = {
      headers: { 'content-type': 'application/json' }
    };

    const response = await axios.post('/Login', JSON.stringify(payload), options);
    const loginRS: LoginRS = response.data.LoginRS;
    // console.log(loginRS);
    if (!loginRS.Status.Status) {
      throw new Error('Invalid Credentials');
    }

    const accessToken: string = loginRS.Token;
    const user: any = loginRS.Employee ? loginRS.Employee : loginRS.ClientEmployee;
    const uiType = loginRS.Employee ? 'BO' : 'B2B';
    const userRights: Rights = loginRS.Rights;

    setSession(accessToken);
    dispatch({
      type: Types.Login,
      payload: {
        user: loginRS.Employee ? loginRS.Employee : loginRS.ClientEmployee,
        b2bClient: loginRS.Employee ? null : loginRS.Client,
        userRights: loginRS.Rights,
        uiType: loginRS.Employee ? 'BO' : 'B2B',
        flightProduct: loginRS.FlightProduct,
        hotelProduct: loginRS.HotelProduct,
        activityProduct: loginRS.ActivityProduct,
        companyName: loginRS.CompanyName,
        companyLogo: loginRS.CompanyLogo
      }
    });
  };

  const register = async (
    companyName: string,
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => {
    const options = {
      headers: { 'content-type': 'application/json' }
    };

    const response = await axios.post(
      '/CompanyRegisteration',
      JSON.stringify({
        Company: companyName,
        Email: email,
        Password: password,
        FirstName: firstName,
        LastName: lastName
      }),
      options
    );

    const status: boolean = response.data.CompanyRegisterationRS.Status;

    if (!status) {
      throw new Error(response.data.CompanyRegisterationRS.Massage);
    } else {
      dispatch({
        type: Types.Register,
        payload: {
          isRegistrationSuccessful: true
        }
      });
    }
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  const resetPassword = (email: string) => {};

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        resetPassword,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
