import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { BookingListSearchCriteria } from '../../@types/common';

// ----------------------------------------------------------------------

type BookingListState = {
  isLoading: boolean;
  error: boolean;
  bookingList: any[];
  booking: any;
  searchHotelCriteria: any;
  searchFlightCriteria: any;
  searchActivityCriteria: any;
  searchState: any;
  previousPath: string;
  activityWizState: any;
  hotelWizState: any;
};

const initialState: BookingListState = {
  isLoading: false,
  error: false,
  bookingList: [],
  booking: null,
  searchHotelCriteria: {},
  searchFlightCriteria: {},
  searchActivityCriteria: {},
  searchState: {},
  previousPath: '',
  activityWizState: undefined,
  hotelWizState: undefined
};

const slice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.bookingList = [];
      state.booking = null;
      state.searchHotelCriteria = {};
      state.searchFlightCriteria = {};
      state.searchActivityCriteria = {};
      state.searchState = {};
      state.previousPath = '';
      state.activityWizState = undefined;
      state.hotelWizState = undefined;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.bookingList = [];
      state.booking = null;
      state.searchHotelCriteria = {};
      state.searchFlightCriteria = {};
      state.searchActivityCriteria = {};
      state.searchState = {};
      state.previousPath = '';
      state.activityWizState = undefined;
      state.hotelWizState = undefined;
    },

    // By Id ENTITIES
    getBookingByIdSuccess(state, action) {
      state.isLoading = false;
      state.booking = action.payload;
    },

    // GET ENTITIES
    getBookingListSuccess(state, action) {
      state.isLoading = false;
      state.bookingList = action.payload;
    },
    setHotelSearchCriteriaSuccess(state, action) {
      state.searchHotelCriteria = action.payload;
    },
    setFlightSearchCriteriaSuccess(state, action) {
      state.searchFlightCriteria = action.payload;
    },
    setActivitySearchCriteriaSuccess(state, action) {
      state.searchActivityCriteria = action.payload;
    },
    setSearchCommonStateSuccess(state, action) {
      state.searchState = action.payload;
    },
    setPreviousPathSuccess(state, action) {
      state.previousPath = action.payload;
    },
    setActivityWizardSearchSuccess(state, action) {
      state.activityWizState = action.payload;
    },
    setHotelWizardSearchSuccess(state, action) {
      state.hotelWizState = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function GetBookingById(bookingId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/GetBookingById/${bookingId}`);
      console.log('response.data.response', response.data.response);
      dispatch(slice.actions.getBookingByIdSuccess(response.data.response));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function GetBookingList(payload: BookingListSearchCriteria) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/GetBookingList', JSON.stringify(payload));
      dispatch(slice.actions.getBookingListSuccess(response.data.response));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setHotelSearchCriteriaState(searchCriteria: any) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setHotelSearchCriteriaSuccess(searchCriteria));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setFlightSearchCriteriaState(searchCriteria: any) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setFlightSearchCriteriaSuccess(searchCriteria));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setActivitySearchCriteriaState(searchCriteria: any) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setActivitySearchCriteriaSuccess(searchCriteria));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setSearchCommonState(searchState: any) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setSearchCommonStateSuccess(searchState));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setActivityWizardSearch(state: any) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setActivityWizardSearchSuccess(state));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function setHotelWizardSearch(state: any) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setHotelWizardSearchSuccess(state));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setPreviousPath(path: string) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setPreviousPathSuccess(path));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
