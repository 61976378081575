import { useLocation, Outlet } from 'react-router-dom';
// @mui
import { Box, Stack } from '@mui/material';
// components
import { experimentalStyled as styled } from '@mui/material/styles';
import MainFooter from './MainFooter';
import MainHeader from './MainHeader';

// ----------------------------------------------------------------------
const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 64;

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 20,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 20,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

export default function B2CLayout() {
  const { pathname } = useLocation();

  return (
    <Stack sx={{ minHeight: 1 }}>
      <MainHeader />

      <MainStyle>
        <Outlet />
      </MainStyle>

      <Box sx={{ flexGrow: 1 }} />
      <MainFooter />
    </Stack>
  );
}
